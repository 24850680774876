import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import LinkCard from   "../../../../components/LinkCard/LinkCard";

const HomepageBigPromise = ({
  homePageBannerImage,
  electricVehicles
}) => {

  const electricVehicleCount = electricVehicles ? electricVehicles.length : "many";
  
  return (
    <section className="container p-0">
      <div className="big-promise" style={{ position: "relative" }}>
        <div className="gradient-top-left" />
        <div
          style={{
            backgroundImage: `url(${homePageBannerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "475px",
            boxSizing: "border-box"
          }}
        />
        <div className="big-promise-caption">
          <p>Your Guide to Electric Vehicles</p>
          <p>Compare costs, savings, incentives, and more.</p>
        </div>
      </div>

      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="DISCOVER_INCENTIVES" />
              <LinkCard type="LOCATE_CHARGING_STATIONS" />
              {/* <LinkCard type="EV_FACTS" /> */}
              {/* <LinkCard type="FIND_DEALERS" /> */}
            </div>
          </div>
        </div>
      </div>
      <div id="why-choose-evs">
        <div className="row">
          <div className="col-sm-12">
            <p className="h2">Why choose electric vehicles.</p>
          </div>
        </div>
        <div className="row">
          <div className="mt-3 col-sm-5">
            <p className="h5 text-uppercase">Save money</p>
            <p className="">
              Electric vehicles can be cheaper to fuel and own than gas cars
              when you compare total cost of ownership.
            </p>
          </div>
          <div className="mt-3 col-sm-5 offset-sm-1">
            <p className="h5 text-uppercase">Less Hassle</p>
            <p className="">
              Many electric vehicles have no regular maintenance - no oil,
              filters, or belts to change. You can even charge at home using
              your wall outlet.
            </p>
          </div>
          <div className="mt-3 col-sm-5">
            <p className="h5 text-uppercase">Drive Anywhere</p>
            <p className="">
              Ever-improving batteries and charging infrastructure provide
              freedom and peace of mind when going the distance.
            </p>
          </div>
          <div className="mt-3 col-sm-5  offset-sm-1">
            <p className="h5 text-uppercase">Vast Selection of Car Models</p>
            <p className="">
              There are {electricVehicleCount} available electric vehicles in
              the {process.env.REACT_APP_COMPANY_CITY} area. Compare the cost of your selection to a
              similar gas vehicle.
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-3">
            <Link to="/ev101" className="btn btn-dlc" role="button">
              Learn More
            </Link>
          </div>
        </div>
        <hr className="mt-5" />
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}