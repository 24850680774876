import React from 'react';
import { FormatAsDollars, FormatCarName } from '../../../utils/Helpers/Format';
import "./RateComparisonBanner.scss";

const PriceDifferenceMessage = ({
  comparedRateTotal,
  costDifference,
  currentRateTotal,
  selectedVehicle,
}) => {
  const actionText = comparedRateTotal.rateId === currentRateTotal.rateId ?
    "adding a" :
    `switching to ${comparedRateTotal.title.join(" ")} with a`;

  return (
    <>
      You can save
      {" "}
      <span className="highlight">
        ~{FormatAsDollars(costDifference)}
      </span>
      {" "}
      per year by
      {" "}
      {actionText}
      <br />
      {`${selectedVehicle.model_year} ${FormatCarName(selectedVehicle)}`}
    </>
  );
};

const NoCostDifferenceMessage = ({ currentRateTotal: { title }, selectedVehicle }) => {
  return (
    <>
      The current {title.join(" ")} is the cheapest rate with the
      <br />
      {`${selectedVehicle.model_year} ${FormatCarName(selectedVehicle)}`}
    </>
  );
};

const RateComparisonBanner = ({
  rateTotals,
  featuredRateId,
  selectedVehicle
}) => {
  const currentRateTotal = rateTotals[0];
  let comparedRateTotal;
  if (featuredRateId && rateTotals.find(total => total.id === featuredRateId)) {
    comparedRateTotal = rateTotals.find(total => total.id === featuredRateId);
  } else {
    comparedRateTotal = rateTotals.find(total => {
      return total.monthly === Math.min(...rateTotals.map(t => t.monthly));
    });
  }

  const currentAnnualCost = currentRateTotal.monthly * 12;
  const comparedAnnualCost = comparedRateTotal.monthly * 12;

  const costDifference = currentAnnualCost - comparedAnnualCost;
  if (costDifference < 0) return null;

  return (
    <div className="RateComparisonBanner">
      {costDifference > 0 && (
        <PriceDifferenceMessage
          comparedRateTotal={comparedRateTotal}
          currentRateTotal={currentRateTotal}
          costDifference={costDifference}
          selectedVehicle={selectedVehicle}
        />
      )}
      {costDifference === 0 && (
        <NoCostDifferenceMessage
          selectedVehicle={selectedVehicle}
          currentRateTotal={currentRateTotal}
        />
      )}
    </div>
  );
};

export default RateComparisonBanner;
