import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

import "./ToggleIsElectricVehicleOwner.scss";

const ToggleIsElectricVehicleOwner = () => {
  const userPrefs = useContext(UserPrefsContext);
  const { isElectricVehicleOwner } = userPrefs.get();

  return (
    <div className="ToggleIsElectricVehicleOwner">
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="toggle-isElectricVehicleOwner"
          checked={isElectricVehicleOwner}
          onChange={() => userPrefs.set({ isElectricVehicleOwner: !isElectricVehicleOwner })}
        />
        <label className="custom-control-label" htmlFor="toggle-isElectricVehicleOwner">
          I already own this EV
        </label>
      </div>
    </div>
  );
};

export default ToggleIsElectricVehicleOwner;
