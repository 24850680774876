import { RateCalculator, LoadProfile } from "@bellawatt/electric-rate-engine";
import {times} from 'lodash'

RateCalculator.shouldValidate = false;

class BasePersona {
  monthlyCosts(rate) {
    return {
      home: this._homeCost(rate) / 12,
      ev: this._evCost(rate) / 12,
      total: this._totalCost(rate) / 12,
    };
  }

  _totalCost(rate) {
    return new RateCalculator({
      ...rate.data,
      loadProfile: this._totalCustomerLoadProfile,
    }).annualCost();
  }
}

class EvOwner extends BasePersona {
  constructor({
    typicalHomeLoadProfile: { year, data: homeData },
    currentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile,
  }) {
    super();
    // The EV load profile needs to ignore fixed costs, so that they end up being
    // allocated to the home portion of the bill.
    const evLoadProfile = new LoadProfile(
      chargingPatternLoadProfile.data(year),
      {year}
    )
      .scale()
      .toTotalKwh(homeChargingKwh);

    // The bill with fixed costs
    const evMonthlyBill = new RateCalculator({
      ...currentRate.data,
      loadProfile: evLoadProfile,
    }).annualCost() / 12;

    const fixedMonthlyCost = new RateCalculator({
      ...currentRate.data,
      loadProfile: new LoadProfile(times(8760, () => 0), {year}),
    }).annualCost() / 12;

    // This ends up being the load profile for the homeChargingKwh usage
    // that ignores the fixed costs.
    const evMonthlyEnergyCost = evMonthlyBill - fixedMonthlyCost;
    this._electricVehicleLoadProfile = evMonthlyEnergyCost <= 0 ?
      evLoadProfile :
      evLoadProfile
        .scale()
        .toAverageMonthlyBill(evMonthlyEnergyCost, currentRate.data);

    // It's a bit unintuitive, but we also want to ignore the fixed costs when
    // scaling the home load profile. We are trying to get the home load profile
    // that makes this equation true:
    //
    // EV Energy charges + Home Energy Charges + Fixed costs = currentMonthlyBill.
    //
    // If we include the fixed costs while scaling the home load profile
    // (by not adding them to the homeMonthlyBill that we scale to),
    // then we actually end up with:
    //
    // EV Energy charges + (Home Energy Charges + Fixed charges) + Fixed costs = currentMonthlyBill;
    //
    // That would make Home Energy Charges too low when calculating _totalCost.
    //
    // NOTE: This method would not work if there were any rates that charge by
    // the kW instead of kWh. Demand charges or blocked tier charges are the most
    // common charges that fall into that category, but DLC does not currently
    // offer any rates with those charges to residential customer.
    const homeMonthlyBill = currentMonthlyBill - evMonthlyEnergyCost + fixedMonthlyCost;

    this._homeLoadProfile = new LoadProfile(homeData, { year })
      .scale()
      .toAverageMonthlyBill(homeMonthlyBill, currentRate.data);

    this._totalCustomerLoadProfile = this._homeLoadProfile.aggregate(this._electricVehicleLoadProfile);
  }

  _homeCost(rate) {
    return this._totalCost(rate) - this._evCost(rate);
  }

  _evCost(rate) {
    return new RateCalculator({
      ...rate.data,
      loadProfile: this._electricVehicleLoadProfile,
    }).annualCost();
  }
}

class NonEvOwner extends BasePersona {
  constructor({
    typicalHomeLoadProfile: { year, data: homeData },
    currentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile,
  }) {
    super();
    this._electricVehicleLoadProfile = new LoadProfile(
      chargingPatternLoadProfile.data(year),
      {year}
    )
      .scale()
      .toTotalKwh(homeChargingKwh);

    const homeMonthlyBill = currentMonthlyBill;

    this._homeLoadProfile = new LoadProfile(homeData, { year })
      .scale()
      .toAverageMonthlyBill(homeMonthlyBill, currentRate.data);

    const combinedLoadProfile = this._homeLoadProfile.aggregate(this._electricVehicleLoadProfile);

    this._totalCustomerLoadProfile = combinedLoadProfile;
  }

  _homeCost(rate) {
    return new RateCalculator({
      ...rate.data,
      loadProfile: this._homeLoadProfile,
    }).annualCost();
  }

  _evCost(rate) {
    return this._totalCost(rate) - this._homeCost(rate);
  }
}

class RateCostCalculator {
  constructor({
    isElectricVehicleOwner = false,
    ...data
  }) {
    this._persona = isElectricVehicleOwner ?
      new EvOwner(data) :
      new NonEvOwner(data);
  }

  monthlyCosts(rate) {
    return this._persona.monthlyCosts(rate);
  }
}

export default RateCostCalculator;
