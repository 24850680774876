import React, { useState, useContext } from "react";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext"
import PropTypes from "prop-types";
import "./PricePanels.scss";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { FormatAsDollars } from "../../../../utils/Helpers/Format"
import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"


const scrollToCostsButton = (
  <button
    className="btn btn-sm btn-default"
    onClick={e => SmoothScroll("CostOfOwnership")}
  >
    All Costs &#62;
  </button>
);

const scrollToIncentivesButton = (
  <button
    className="btn btn-sm btn-default"
    onClick={e => SmoothScroll("EVIncentives")}
  >
    All Incentives &#62;
  </button>
);


const PricePanels = ({ paymentDetails }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [activeTab, setTab] = useState("1");

  const updatePurchaseMethod = (purchaseMethodValue) => {
    userPrefs.set({ purchaseMethod: purchaseMethodValue });
    switch (purchaseMethodValue) {
      case "cash":
        setTab("1");
        break;
      case "loan":
        setTab("2");
        break;
      case "lease":
        setTab("3");
        break;
      default:
        setTab("1");
    }

  }



  if (!paymentDetails) return null;

  return (
    <>
      <div className="PricePanels">
        <Nav pills className="nav-fill">
          <NavItem>
            <NavLink
              className={(userPrefs.get("purchaseMethod") === "cash") ? "active" : ""}
              onClick={() => updatePurchaseMethod("cash")}
              onKeyPress={() => userPrefs.set({ purchaseMethod: "cash" })}
              tabIndex="0"
            >
              <span>Cash</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={userPrefs.get("purchaseMethod") === "loan" ? "active" : ""}
              onClick={() => updatePurchaseMethod("loan")}
              onKeyPress={() => userPrefs.set({ purchaseMethod: "loan" })}
              tabIndex="0"
            >
              <span>Loan</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={userPrefs.get("purchaseMethod") === "lease" ? "active" : ""}
              onClick={() => updatePurchaseMethod("lease")}
              onKeyPress={() => userPrefs.set({ purchaseMethod: "lease" })}
              tabIndex="0"
            >
              <span>Lease</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">After Incentives</p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.afterIncentives)}
                    </p>
                  </div>
                </div>
                <div className="PanelItem">
                  <div className="row">
                    <div className="col">
                      <p className="RenderItemTitle">MSRP</p>
                      <p className="h3">
                        {FormatAsDollars(paymentDetails.msrp)}
                      </p>
                      {scrollToCostsButton}
                    </div>
                    <div className="col">
                      <p className="RenderItemTitle">Estimated Incentives</p>
                      <p className="h3">
                        {FormatAsDollars(
                          paymentDetails.totalIncentivesForPurchase
                        )}
                      </p>
                      {scrollToIncentivesButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">Loan Payment</p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.loanAmountPerMonth) +
                        " / month"}
                    </p>
                  </div>
                </div>
                <div className="PanelItem">
                  <div className="row">
                    <div className="col">
                      <p className="RenderItemTitle">Down Payment</p>
                      <p className="h2">
                        {FormatAsDollars(paymentDetails.downPayment)}
                      </p>
                      <small>10% of MSRP plus tax</small>
                    </div>
                    <div className="col">
                      <p className="RenderItemTitle">Estimated Incentives</p>
                      <p className="h2">
                        {FormatAsDollars(
                          paymentDetails.totalIncentivesForPurchase
                        )}
                      </p>
                      {scrollToIncentivesButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">Lease Payment</p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.leaseAmountPerMonth) +
                        " / month"}
                    </p>
                  </div>
                </div>
                <div className="PanelItem">
                  <div className="row">
                    <div className="col">
                      <p className="RenderItemTitle">First Lease Payment</p>
                      <p className="h2">
                        {FormatAsDollars(paymentDetails.leaseFirstPayment)}
                      </p>
                      {scrollToCostsButton}
                    </div>
                    <div className="col">
                      <p className="RenderItemTitle">Incentives For Lease</p>
                      <p className="h2">
                        {FormatAsDollars(
                          paymentDetails.totalIncentivesForLease
                        )}
                      </p>
                      {scrollToIncentivesButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default PricePanels;

PricePanels.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};