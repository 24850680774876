import {
  GAS_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  HOME_ENERGY_COST_ID
} from '../constants/';

const energyCostTypes = [
  {
    id: HOME_ENERGY_COST_ID,
    title: 'Home',
    backgroundColor: "#60BAAC"
  },
  {
    id: EV_ENERGY_COST_ID,
    title: 'Electric Vehicle',
    backgroundColor: "#EF8131"
  },
  {
    id: GAS_ENERGY_COST_ID,
    title: 'Gasoline',
    backgroundColor: "#000000"
  },
];

export default energyCostTypes;
