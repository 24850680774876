import { useEffect, useState } from 'react';

const useSelectedVehicle = (electricVehicles, selectedVehicleId) => {
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [randomVehicleId, setRandomVehicleId] = useState();

  useEffect(() => {
    if (!randomVehicleId && electricVehicles) {
      const randomIdx = Math.floor(Math.random() * electricVehicles.length);
      setRandomVehicleId(
        electricVehicles[randomIdx].electric_vehicle_id
      );
    }
  }, [electricVehicles, randomVehicleId]);

  useEffect(() => {
    if (!electricVehicles) return;

    const vehicle = electricVehicles.find(ev => ev.electric_vehicle_id === parseInt(selectedVehicleId, 10)) ||
      electricVehicles.find(ev => ev.electric_vehicle_id === randomVehicleId);

    if (vehicle) {
      setSelectedVehicle(vehicle);
    }
  }, [electricVehicles, selectedVehicleId, randomVehicleId]);

  return selectedVehicle;
}

export default useSelectedVehicle;
