import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const SelectChargingPattern = ({ chargingPatterns }) => {
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Select
      id="rate-comparison-charging-pattern"
      value={userPrefs.get('chargingPatternId')}
      optionNames={chargingPatterns.map(p => (`${p.title} (${p.hours})`))}
      optionValues={chargingPatterns.map(p => p.id)}
      handler={(e) => userPrefs.set({chargingPatternId: e.target.value})}
      label="Charging Pattern"
    />
  );
};

export default SelectChargingPattern;
