const totalElectricVehicleKwhPerYear = ({
  kwhPer100Miles,
  milesDrivenAnnually,
  percentageDrivenElectric,
  model_year,
}) => {
  return (
    (kwhPer100Miles / 100) *
    milesDrivenAnnually *
    (percentageDrivenElectric / 100) *
    ((1.15 * 1.04) ^ (new Date().getFullYear() - model_year))
  );
};

export default totalElectricVehicleKwhPerYear;
