import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TabbedMaps from '../../components/TabbedMaps/TabbedMaps';
import useMapTabs from '../../hooks/useMapTabs';

const MapPage = ({ userLocation, ip, uuid, history, dealerLocations, tabId, title, zipcode }) => {
  useEffect(() => {
    document.title = title;
  });

  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = newTabId => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  return (
    <>
    <section className="container">
      <h1 className="hide-offscreen">Map</h1>

      <TabbedMaps
        toggle={toggleWithHistory}
        activeTabId={activeTabId}
        userLocation={userLocation}
        dealerLocations={dealerLocations}
        zipcode={zipcode}
      />

    </section>
  </>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
