import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const socialIcons = (
  <div className="social-icons mb-2">
    <a
      href="http://www.facebook.com/DuquesneLight"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 75.36"
        className="icon icon--reverse-fill icon--social"
        role="img"
        style={{ color: "rgb(255, 255, 255)" }}
      >
        <title>social-icons-facebook</title>
        <path d="M0,75.18H75v-75H0v75Zm67.29-54.5H61.21c-4.77,0-5.69,2.27-5.69,5.59V33.6H66.89L65.41,45.08H55.52V74.53H43.67V45.08H33.75V33.6h9.91V25.13c0-9.83,6-15.18,14.76-15.18a80.82,80.82,0,0,1,8.86.45V20.68Z" />
      </svg>
    </a>
    <a
      href="http://www.twitter.com/DuquesneLight"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 75.36"
        className="icon icon--reverse-fill icon--social"
        role="img"
        style={{ color: "rgb(255, 255, 255)" }}
      >
        <title>social-icons-twitter</title>
        <rect width="448.08" height="75.36" style={{ fill: "none" }} />
        <path d="M-0.18,76.36H75.18V1H-0.18V76.36ZM57.48,28.43c0,0.44,0,.89,0,1.34,0,13.62-10.37,29.32-29.32,29.32a29.19,29.19,0,0,1-15.8-4.63,21.47,21.47,0,0,0,2.46.14,20.68,20.68,0,0,0,12.8-4.41A10.32,10.32,0,0,1,18,43a10.22,10.22,0,0,0,1.94.19,10.38,10.38,0,0,0,2.72-.36A10.32,10.32,0,0,1,14.4,32.75V32.63a10.29,10.29,0,0,0,4.67,1.29,10.32,10.32,0,0,1-3.19-13.76A29.25,29.25,0,0,0,37.12,30.92a10.31,10.31,0,0,1,17.57-9.4A20.56,20.56,0,0,0,61.23,19a10.34,10.34,0,0,1-4.53,5.7,20.6,20.6,0,0,0,5.92-1.62,21,21,0,0,1-5.14,5.33" />
      </svg>
    </a>
    <a
      href="https://www.linkedin.com/company/duquesne-light-company"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 75.36"
        className="icon icon--reverse-fill icon--social"
        role="img"
        style={{ color: "rgb(255, 255, 255)" }}
      >
        <title>social-icons-linkedin</title>
        <path d="M0.36,75.36H75.72V0H0.36V75.36ZM23.89,63.25H13.25V29.09H23.89V63.25ZM18.57,24.42a6.16,6.16,0,1,1,6.16-6.16,6.16,6.16,0,0,1-6.16,6.16M63.67,63.25H53.05V46.64c0-4-.08-9.06-5.52-9.06s-6.37,4.31-6.37,8.77v16.9H30.55V29.09H40.73v4.67h0.14a11.16,11.16,0,0,1,10.05-5.52c10.75,0,12.74,7.07,12.74,16.27V63.25Z" />
        <rect x="-92" width="448.08" height="75.36" style={{ fill: "none" }} />
        <rect width="448.08" height="75.36" style={{ fill: "none" }} />
        <rect x="2" width="448.08" height="75.36" style={{ fill: "none" }} />
        <rect x="1" width="448.08" height="75.36" style={{ fill: "none" }} />
      </svg>
    </a>
  </div>
);

const Footer = () => {
  return (
    <>
      <section className="container" style={{ backgroundColor: "#ffffff" }}>
        <p className="footer-big-promise">
          We don&#39;t just power your lights, we power the moments you call
          life.
          <br />
          <span style={{ color: "#D68100" }}>
            One more reason we&#39;re larger than light.
          </span>
        </p>
      </section>
      <div className="Footer">
        <div className="container py-5">
          <div className="row">
            <div className="d-none d-md-block col-12">
              <div className="d-flex justify-content-between">
                <Link to="/">Home</Link>
                {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
                  <Link to="/vehicles">{process.env.REACT_APP_PAGES_VEHICLES_TITLE}</Link>
                </>) : (null)}
                {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
                  <Link to="/incentives">{process.env.REACT_APP_PAGES_INCENTIVES_TITLE}</Link>
                </>) : (null)}
                {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
                  <Link to="/charging-stations">{process.env.REACT_APP_PAGES_CHARGING_TITLE}</Link>
                </>) : (null)}
                {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
                  <Link to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}>
                    {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</Link>
                </>) : (null)}
                {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
                  <Link to="/dealers">{process.env.REACT_APP_PAGES_DEALERS_TITLE}</Link>
                </>) : (null)}
                {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
                  <Link to="/ev101">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
                </>) : (null)}
              </div>
              <div className="text-right">
                <p className="mt-4 small ml-auto">Powered by J.D. Power ZappyRide</p>
              </div>
            </div>

            <div className="d-block d-md-none col-6">
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/vehicles">Electric Vehicles</Link>
              </p>
              <p>
                <Link to="/incentives">Incentives</Link>
              </p>
              <p>
                <Link to="/charging-stations">Charging Stations</Link>
              </p>
              <p>
                <Link to="/ev101">EV 101</Link>
              </p>
            </div>
            <div className="d-flex d-md-none col-6">
              <p className="small ml-auto align-self-end">
                Powered by J.D. Power ZappyRide
              </p>
            </div>

          </div>

          <div className="row pb-5">
            <div className="col text-center">
              <p className="small">
                <a
                  style={{ color: "white", textTransform: "lowercase" }}
                  href={process.env.REACT_APP_COMPANY_CONTACT}
                >
                  electricvehicles@duqlight.com
                </a>
              </p>
              {socialIcons}
              <p className="small">
                <span style={{ fontFamily: "Arial, Helvetica" }}>&copy;</span>{" "}
                {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved.
              </p>
              <span className="disclaimer_container" >
                  {/* <DisclaimerComponent clientName="zappynobackground"/> */}

                  <DisclaimerComponent clientName="zappynobackground" textStyle={{fontFamily:"font-family proxima-nova, Arial, Helvetica, sans-serif",fontSize:"13px"}}/>

                </span>
              {/* <p className="small">
                <span style={{ fontFamily: "Arial, Helvetica" }}> &copy;
                  {(new Date().getFullYear().toString())} J.D. Power ZappyRide(c). {" "}
                  <a style={{ fontFamily: "Arial, Helvetica", fontWeight: 400 }} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices"> Legal notices.</a>
                </span>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;