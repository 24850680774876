import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CarGallery from "../../../components/EVJumbotron/CarGallery/CarGallery"
import CarDetails from "../../../components/EVJumbotron/CarDetails/CarDetails"
import CarDetailsCalcs from "../../../components/EVJumbotron/CarDetailsCalcs/CarDetailsCalcs"
import VehicleImage from "../../../components/VehicleImage/VehicleImage"
import PricePanels from "./PricePanels/PricePanels";
import { FormatCarName } from "../../../utils/Helpers/Format"
import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton"

const EVJumbotron = ({ car, paymentDetails }) => {
  if (!car) return null;

  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value 
  let imagesSrc = car && car["images"] ? car.images.map((n) =>
    (n.url_thumbnail == null) ? [] :
      (n.url_full == null) ? [] :
        [n]) : [];


   

//Flatten evImgs into a single array                  
const images = [].concat.apply([], imagesSrc);



  let carImages = images.map(image => {
    return {
      original: image.url_full,
      thumbnail: image.url_thumbnail,
      originalAlt: image["legal_info"],
      thumbnailAlt: image.title
    };
  });
  carImages.shift();

  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col">
            <h1>{FormatCarName(car)}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-8">
            <div className="text-center offset-lg-1 col-lg-10 col-md-12">
              <VehicleImage image={images[0]} size="full" alt={FormatCarName(car)} />
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4">
            <PricePanels car={car} paymentDetails={paymentDetails} />
            <br />
            <Link to="/vehicles">
              <button className="btn btn-sm btn-default">View all cars</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-7">
            <CarDetails car={car} />
            <br />
            <div className="text-center">
              <ShowHideButton
                buttonText=" detailed calculations"
                displayAlertOnlyOnMobile
              >
                <div className="input-well">
                  <CarDetailsCalcs car={car} />
                </div>
              </ShowHideButton>
            </div>
            <br />
            <p>
              See the{" "}
              <Link to="/ev101" className="inline-link" style={{textDecoration: 'underline'}}>
                EV 101
              </Link>{" "}
              page for detailed explanations about your EV.
            </p>
          </div>
          <div className="col-md-5">
            <CarGallery carImages={carImages} />
          </div>
        </div>
        <br />
      </section>
    </>
  );
};

export default EVJumbotron;

EVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};