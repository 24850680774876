import React, { useContext } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormatAsPercentRounded } from '../../../../utils/Helpers/Format';
import Range from '../../../../components/shared/InputElements/Range';

const descriptionFn = val => FormatAsPercentRounded(val);

const SlidePublicChargingPercentage = ({
  id = "public-charging-percentage-range",
  label = "Portion of Public Charging",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("publicChargingPercentage")}
      label={label}
      rangeMin={0}
      rangeMax={100}
      rangeStep={10}
      description={description}
      handler={e => userPrefs.set({ publicChargingPercentage: e.target.value })}
      {...rest}
    />
  );
};

export default SlidePublicChargingPercentage;

SlidePublicChargingPercentage.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
