import React from "react";

const Input = props => {
  const { id, value, handler, label, description, isToggleSwitch } = props;

  const idAria = id + " help";

  return (
    <div
      className={isToggleSwitch ? "custom-control custom-switch" : "form-check"}
    >
      <input
        type="checkbox"
        id={id}
        className={isToggleSwitch ? "custom-control-input" : "form-check-input"}
        aria-describedby={idAria}
        checked={value}
        onChange={e => handler(e)}
      />
      <label
        htmlFor={id}
        className={isToggleSwitch ? "custom-control-label" : "form-check-label"}
      >
        {label}
      </label>
      <small id={idAria} className="form-text text-muted">
        {description}
      </small>
    </div>
  );
};

export default Input;
