import "chartjs-plugin-datalabels";

import { FormatAsDollars } from '../../../utils/Helpers/Format';

const chartOptions = ({
  shouldShowSavingsAmount = false,
  currentTotal = 0,
  savingsColor = "#000000",
  labelColor = "#000000",
  legendColor = "#000000",
  axesColor = "#939393"
}) => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      mode: "index",
      callbacks: {
        label: function(tooltipItem, data) {
          const value = FormatAsDollars(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          );
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          return ` ${label}: ${value}`;
        },
        title: function(tooltipItems, data) {
          const total = tooltipItems.reduce(function(acc, item) {
            return acc + item.yLabel;
          }, 0);
          return `${tooltipItems[0].xLabel.join(" ")}: ${FormatAsDollars(
            total
          )}`;
        }
      }
    },
    legend: {
      reverse: false,
      position: "bottom",
      onClick: function(e) {
        e.stopPropagation();
      },
      labels: {
        fontSize: 14,
        fontColor: legendColor,
        fontFamily: "'Open Sans'",
      }
    },
    layout: {
      padding: {
        top: 20
      }
    },
    scales: {
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            callback: function(value) {
              value = FormatAsDollars(value);
              return value;
            },
            fontSize: 14,
            fontColor: axesColor,
            fontFamily: "'Open Sans'",
            padding: 15,
            maxTicksLimit: 6,
          },
          scaleLabel: {
            display: true,
            labelString: "Estimated average cost per month",
            fontSize: 14,
            fontColor: axesColor,
            fontFamily: "'Open Sans'",
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: {
            fontSize: 14,
            fontWeight: 600,
            fontColor: axesColor,
            fontFamily: "'Open Sans'",
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.datasetIndex !== ctx.chart.data.datasets.length - 1) return '';

          const currentMonthlyAmount = ctx.chart.data.datasets
            .reduce((total, dataset) => total + dataset.data[0], 0);
          const monthlyAmountForRate = ctx.chart.data.datasets
            .reduce((total, dataset) => total + dataset.data[ctx.dataIndex], 0);

          if (ctx.dataIndex === 0) return `${FormatAsDollars(currentMonthlyAmount)}/mo`;
          if (!shouldShowSavingsAmount) return `${FormatAsDollars(monthlyAmountForRate)}/mo`;
          if (monthlyAmountForRate < currentMonthlyAmount)
            return `Save ${FormatAsDollars(currentMonthlyAmount - monthlyAmountForRate)}/mo`;
          if (monthlyAmountForRate > currentMonthlyAmount)
            return `Lose ${FormatAsDollars(monthlyAmountForRate - currentMonthlyAmount)}/mo`;
          return '';
        },
        align: "end",
        anchor: "end",
        color: (ctx) => {
          if (ctx.datasetIndex !== ctx.chart.data.datasets.length - 1) return labelColor;

          const currentMonthlyAmount = ctx.chart.data.datasets
            .reduce((total, dataset) => total + dataset.data[0], 0);
          const monthlyAmountForRate = ctx.chart.data.datasets
            .reduce((total, dataset) => total + dataset.data[ctx.dataIndex], 0);

          if (monthlyAmountForRate < currentMonthlyAmount) return savingsColor;
          return labelColor;
        },
        font: {
          weight: 700
        }
      }
    }
  };
};

export default chartOptions;
