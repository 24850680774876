import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./ChargingAvailabilityWizard.scss";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl} from 'react-intl';

const ChargingAvailabilityWizard = ({ toggle }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  if (!userPrefs) return null;

  const handleChargerLevel = (e, availability) => {
    userPrefs.set({
      chargerLevel: availability
    });
    if (toggle) toggle();
  };

  const parkingLocation = userPrefs.get("parkingLocation");
  const chargerInstallAllowed = userPrefs.get("chargerInstallAllowed");
  const hasOvernightCharger = userPrefs.get("hasOvernightCharger");
  const hasRegularPowerOutlet = userPrefs.get("hasRegularPowerOutlet");

  const renderParkingLocation = (
    <div className="form-group">
      <label>
        <FormattedMessage 
          id="chargingWizard.carOvernight"
          defaultMessage="Where will you park your car overnight?"
          description="Where will you park your car overnight?"
        />
        </label>
      <select
        className="form-control"
        value={parkingLocation}
        onChange={e =>
          userPrefs.set({
            parkingLocation: e.target.value
          })
        }
      >
        <option value="" />
        <option value="private">
          {intl.formatMessage ? intl.formatMessage({ id: "chargingWizard.parkingGarage", defaultMessage: "Private garage or parking spot"}) : "Private garage or parking spot"}
        </option>
        <option value="street">
          {intl.formatMessage ? intl.formatMessage({ id: "chargingWizard.onTheStreet", defaultMessage: "On the street"}) : "On the street"}
        </option>
        <option value="public">
          {intl.formatMessage ? intl.formatMessage({ id: "chargingWizard.publicParking", defaultMessage: "Public parking lot"}) : "Public parking lot"}
        </option>
      </select>
    </div>
  );

  const renderChargerInstallAllowed = (
    <div className="form-group">
      <label>
        <FormattedMessage 
          id="chargingWizard.electricianInstall"
          defaultMessage="Would an electrician be allowed to install a charging station?"
          description="Would an electrician be allowed to install a charging station?"
        />
      </label>
      <select
        className="form-control"
        value={chargerInstallAllowed === null ? "" : chargerInstallAllowed}
        onChange={e =>
          userPrefs.set({
            chargerInstallAllowed: e.target.value === "true"
          })
        }
      >
        <option value="" />
        <option value="true">
          {intl.formatMessage ? intl.formatMessage({ id: "yes", defaultMessage: "Yes"}) : "Yes"}
        </option>
        <option value="false">
          {intl.formatMessage ? intl.formatMessage({ id: "no", defaultMessage: "No"}) : "No"}
        </option>
      </select>
    </div>
  );

  const renderHasOvernightCharger = (
    <div className="form-group">
      <label>
        <FormattedMessage 
          id="chargingWizard.overnightParking"
          defaultMessage="Could you park the car overnight in a spot equipped with a charging station?"
          description="Could you park the car overnight in a spot equipped with a charging station?"
        />
      </label>
      <select
        className="form-control"
        value={hasOvernightCharger === null ? "" : hasOvernightCharger}
        onChange={e =>
          userPrefs.set({
            hasOvernightCharger: e.target.value === "true"
          })
        }
      >
        <option value="" />
        <option value="true">
          {intl.formatMessage ? intl.formatMessage({ id: "yes", defaultMessage: "Yes"}) : "Yes"}
        </option>
        <option value="false">
          {intl.formatMessage ? intl.formatMessage({ id: "no", defaultMessage: "No"}) : "No"}
        </option>
      </select>
    </div>
  );

  const renderHasRegularPowerOutlet = (
    <div className="form-group">
      <label>
        <FormattedMessage 
          id="chargingWizard.powerOutlet"
          defaultMessage="Is there a regular power outlet close to where you park your car?"
          description="Is there a regular power outlet close to where you park your car?"
        />
      </label>
      <select
        className="form-control"
        value={hasRegularPowerOutlet === null ? "" : hasRegularPowerOutlet}
        onChange={e =>
          userPrefs.set({
            hasRegularPowerOutlet: e.target.value === "true"
          })
        }
      >
        <option value="" />
        <option value="true">
          {intl.formatMessage ? intl.formatMessage({ id: "yes", defaultMessage: "Yes"}) : "Yes"}
        </option>
        <option value="false">
          {intl.formatMessage ? intl.formatMessage({ id: "no", defaultMessage: "No"}) : "No"}
        </option>
      </select>
    </div>
  );

  const getChargerResult = () => {
    let result = null;

    if (parkingLocation === "private") {
      if (chargerInstallAllowed === true) result = "level_2";
      else if (
        chargerInstallAllowed === false &&
        hasRegularPowerOutlet === true
      )
        result = "level_1";
      else if (chargerInstallAllowed !== null && hasRegularPowerOutlet !== null)
        result = "no_charger";
    }
    if (parkingLocation === "public" || parkingLocation === "street") {
      if (hasOvernightCharger === true) result = "level_2";
      else if (hasOvernightCharger !== null) result = "no_charger";
    }

    return result;
  };

  const RenderResult = () => {
    let result = getChargerResult();
    let resultText = "";

    switch (result) {
      case "level_2":
        resultText = (
          <span>
            <span className="text-charger-availability">
              <FormattedMessage 
                id="evs.levelTwo"
                defaultMessage="Level 2"
                description="Level 2"
              />
            </span> 
            <FormattedMessage 
                id="chargingWizard.chargingAvailability"
                defaultMessage="charging availability"
                description="charging availability"
            />
          </span>
        );
        break;
      case "level_1":
        resultText = (
          <span>
            <span className="text-charger-availability">
              <FormattedMessage 
                id="evs.levelOne"
                defaultMessage="Level 1"
                description="Level 1"
              />
            </span>
            <FormattedMessage 
              id="chargingWizard.chargingAvailability"
              defaultMessage="charging availability"
              description="charging availability"
            />
          </span>
        );
        break;
      case "no_charger":
        resultText = (
          <span>
            <span className="text-charger-availability">
              <FormattedMessage 
                id="chargingWizard.noChargingAvailability"
                defaultMessage="no charging availability"
                description="no charging availability"
              />
            </span>
          </span>
        );
        break;
      default:
        resultText = "";
    }

    return result ? (
      <div>
        <p>
          <FormattedMessage 
            id="chargingWizard.youWouldHave"
            defaultMessage="you would have {resultText} "
            description="you would have"
            values= {{
              resultText: resultText
            }}
          />
        </p>
        <button
          type="button"
          className="btn-dlc"
          onClick={e => handleChargerLevel(e, result)}
        >
          <FormattedMessage 
            id="chargingWizard.useThisValue"
            defaultMessage="Use this value "
            description="use this value"
            values= {{
              resultText: resultText
            }}
          />
        </button>
      </div>
    ) : (
      ""
    );
  };

  return (
    <div className="ChargingAvailabilityWizard">
      <div className="input-well">
        {renderParkingLocation}

        {/* Q2 */}
        {parkingLocation === "private" && renderChargerInstallAllowed}
        {(parkingLocation === "street" || parkingLocation === "public") &&
          renderHasOvernightCharger}

        {parkingLocation === "private" &&
          !chargerInstallAllowed &&
          renderHasRegularPowerOutlet}
        <RenderResult />
      </div>
    </div>
  );
};

ChargingAvailabilityWizard.propTypes = {
  toggle: PropTypes.func
};

export default ChargingAvailabilityWizard;
