import React, { useContext } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Select from '../../../../components/shared/InputElements/Select';

const optionNames = [
  "Free",
  "Low: $0.20/kWh",
  "Medium: $0.40/kWh",
  "High: $0.60/kWh"
];

const optionValues = [0, 20, 40, 60];

const SelectPublicChargingCost = ({
  id = "select-public-charging-cost",
  label = "Cost of Public Charging",
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Select
      id={id}
      value={userPrefs.get("publicChargingCostInCentsPerKwh")}
      label={label}
      tooltip="Level 2 public charging is usually free to medium. Cost of fast charging is usually medium to high. Depending on the station, the cost of charging can be driven by session count, session time or by energy used."
      optionNames={optionNames}
      optionValues={optionValues}
      handler={e =>
        userPrefs.set({ publicChargingCostInCentsPerKwh: e.target.value })
      }
      {...rest}
    />
  );
};

export default SelectPublicChargingCost;

SelectPublicChargingCost.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string
};
