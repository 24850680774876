import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Range from '../../../../components/shared/InputElements/Range';
import { FormatAsCents } from '../../../../utils/Helpers/Format';
import { egsRates, dlcRates } from '../../../data/rates';
import ToolTip from '../../../../components/shared/ToolTip/ToolTip';

import "./SlideEGSKwhPrice.scss";

const SlideEGSKwhPrice = () => {
  const userPrefs = useContext(UserPrefsContext);
  const {
    egsPriceInCentsPerKwh,
    hasEgsSupply,
    currentRateId,
    selectedRateDetailsId
  } = userPrefs.get();

  const handleChangeHasEgsSupply = () => {
    const newHasEgsSupply = !hasEgsSupply;
    const newRates = newHasEgsSupply ? egsRates(egsPriceInCentsPerKwh) : dlcRates;
    userPrefs.set({
      hasEgsSupply: newHasEgsSupply,
      currentRateId: newRates.map(r => r.id).includes(currentRateId) ? currentRateId : newRates[0].id,
      selectedRateDetailsId: newRates.map(r => r.id).includes(selectedRateDetailsId) ? selectedRateDetailsId : newRates[0].id
    });
  };

  return (
    <div className="SlideEGSKwhPrice">
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="toggle-hasEgsSupply"
          checked={hasEgsSupply}
          onChange={handleChangeHasEgsSupply}
        />
        <label className="custom-control-label" htmlFor="toggle-hasEgsSupply">
          I have EGS Supply?
            <ToolTip
              message="If you currently use an electric generation supplier instead of Duquesne Light for your electricity supply, please select this option."
              id="egs-supply-tooltip"
            />
        </label>
      </div>

      {hasEgsSupply && (
        <Range
          id="rate-comparison-egs-kwh-price"
          value={Number(egsPriceInCentsPerKwh)}
          handler={(e) => userPrefs.set({egsPriceInCentsPerKwh: e.target.value})}
          label="EGS Supply Rate"
          rangeMin={1}
          rangeMax={15}
          rangeStep={1}
          description={val => `${FormatAsCents(val / 100)} / kWh`}
        />
      )}
    </div>
  );
};

export default SlideEGSKwhPrice;
