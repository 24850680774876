import isPHEV from '../../functions/vehicle/isPHEV';

class GasolineCostCalculator {
  constructor({
    selectedVehicle,
    gasolinePriceInCentsPerGal,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    equivalentMilesPerGallon
  }) {
    const {
      fossil_fuel_efficiency: evEfficiency,
      equivalent_gas_vehicle: {
        fossil_fuel_efficiency: equivalentGasVehicleEfficiency,
      } = { fossil_fuel_efficiency: undefined }
    } = selectedVehicle || {};

    this.evEfficiency = evEfficiency;
    this.equivalentGasVehicleEfficiency = equivalentMilesPerGallon || equivalentGasVehicleEfficiency;
    this.milesDrivenAnnually = milesDrivenAnnually;
    this.selectedVehicle = selectedVehicle;
    this.electricMilesPortionForPhev = electricMilesPortionForPhev;
    this.gasolinePriceInCentsPerGal = gasolinePriceInCentsPerGal;
  }

  monthlyCosts() {
    return {
      equivalentGasVehicle: this._equivalentGasVehicleCost() / 12,
      ev: this._evCost() / 12,
    }
  }

  _equivalentGasVehicleCost() {
    const cost = this._gasPriceInDollars() *
      this.milesDrivenAnnually /
      this.equivalentGasVehicleEfficiency;

    return isFinite(cost) ? cost : 0;
  }

  _gasPriceInDollars() {
    return this.gasolinePriceInCentsPerGal / 100;
  }

  _evCost() {
    const cost =  this._gasPriceInDollars() *
      (1 / this.evEfficiency) *
      this._percentageGas() / 100 *
      this.milesDrivenAnnually

    return isFinite(cost) ? cost : 0;
  }

  _percentageGas() {
    return isPHEV(this.selectedVehicle) ?
      100 - this.electricMilesPortionForPhev :
      0;
  }
}

export default GasolineCostCalculator;
