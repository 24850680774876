import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import InputPanel from '../../components/InputPanel/InputPanel';
import { egsRates, dlcRates } from '../../data/rates';
import SelectCurrentRate from '../../components/InputComponents/SelectCurrentRate/SelectCurrentRate';
import SelectRateComparisonVehicle from '../../components/InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import useSelectedVehicle from '../../hooks/useSelectedVehicle';
import SlideMilesDrivenAnnually from '../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import SelectChargingPattern from '../../components/InputComponents/SelectChargingPattern/SelectChargingPattern';
import chargingPatterns from '../../data/chargingPatterns';
import isPHEV from '../../../functions/vehicle/isPHEV';
import SlideElectricMilesPortionForPhev from '../../../components/InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev';
import SlideCurrentMonthlyBill from '../../components/InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill';
import SlideGasolinePrice from '../../../components/InputComponents/SlideGasolinePrice/SlideGasolinePrice';
import { FormatAsCents } from '../../../utils/Helpers/Format';
import SlideEquivalentMilesPerGallon from '../../components/InputComponents/SlideEquivalentMilesPerGallon/SlideEquivalentMilesPerGallon';
import SlidePublicChargingPercentage from '../../components/InputComponents/SlidePublicChargingPercentage/SlidePublicChargingPercentage';
import SelectPublicChargingCost from '../../components/InputComponents/SelectPublicChargingCost/SelectPublicChargingCost';
import RateComparisonBanner from '../../components/RateComparisonBanner/RateComparisonBanner';
import calculateRateTotals from '../../functions/calculateRateTotals';
import RateCostCalculator from '../../functions/RateCostCalculator';
import totalElectricVehicleKwhPerYear from '../../functions/totalElectricVehicleKwhPerYear';
import GasolineCostCalculator from '../../functions/GasolineCostCalculator';
import RateComparisonChart from '../../components/RateComparisonChart/RateComparisonChart';
import rateComparisonChartData from '../../functions/rateComparisonChartData';
import RateDetails from '../../components/RateDetails/RateDetails';
import RateOptionsWizard from '../../components/RateOptionsWizard/RateOptionsWizard';

import "./RateComparison.scss";
import residentialLoadProfile from "../../data/loadProfiles/residential2019.json";
import residentialEGSLoadProfile from "../../data/loadProfiles/residentialEGS2019.json"
import SlideEGSKwhPrice from '../../components/InputComponents/SlideEGSKwhPrice/SlideEGSKwhPrice';
import energyCostTypes from '../../data/energyCostTypes';
import archetypeVehicles from '../../data/archetypeVehicles';
import ToggleIsElectricVehicleOwner from '../../components/InputComponents/ToggleIsElectricVehicleOwner/ToggleIsElectricVehicleOwner';

const RateComparison = ({ ip, uuid, usedElectricVehicles: apiVehicles }) => {
  useEffect(() => {
    document.title =
      process.env.REACT_APP_PAGES_RATES_TITLE || "Electric Rate Advisor";
  });

  const userPrefs = useContext(UserPrefsContext);
  const {
    chargingPatternId,
    currentRateId,
    currentMonthlyBill,
    milesDrivenAnnually,
    egsPriceInCentsPerKwh,
    electricMilesPortionForPhev,
    equivalentMilesPerGallon,
    gasolinePriceInCentsPerGal,
    hasEgsSupply,
    isComparingLowestRateOnly,
    isElectricVehicleOwner,
    publicChargingPercentage,
    publicChargingCostInCentsPerKwh,
    selectedEnergyCostIds,
    vehicleIdForRateComparison,
  } = userPrefs.get();


  const electricVehicles = [
    ...archetypeVehicles,
    ...(apiVehicles || []),
  ];

  // NOTE: because usedElectricVehicles load after first render, this hook will always set
  // one of the archetypeVehicles as the default vehicle for first time users.
  const selectedVehicle = useSelectedVehicle(
    electricVehicles,
    vehicleIdForRateComparison
  );

  const currentChargingPattern =
    chargingPatterns.find((p) => p.id === chargingPatternId) ||
    chargingPatterns[0];

  const rates = hasEgsSupply ? egsRates(egsPriceInCentsPerKwh) : dlcRates;
  const currentRate =
    rates.find((rate) => rate.id === currentRateId) || rates[0];
  const isLoading = !apiVehicles

  // DLC's load profiles are an aggregate of all residential customers, and because
  // the values are so large, the load profile scaler fails to converge on occasion
  // Preemptively scaling down before trying to goal seek on the rate calculation
  // prevents that. It would also be reasonable to just scale the raw data down,
  // but we are leaving it untouched and scaling here instead.
  const loadProfileData = (
    hasEgsSupply ? residentialEGSLoadProfile : residentialLoadProfile
  ).map((val) => val / 100);
  const homeLoadProfile = {
    data: loadProfileData,
    year: 2019,
  };

  const totalElectricVehicleKwh = totalElectricVehicleKwhPerYear({
    kwhPer100Miles:
      selectedVehicle && selectedVehicle.electric_efficiency
        ? selectedVehicle.electric_efficiency
        : 0,
    milesDrivenAnnually,
    percentageDrivenElectric: isPHEV(selectedVehicle)
      ? electricMilesPortionForPhev
      : 100,
    model_year:
      selectedVehicle && selectedVehicle.model_year
        ? selectedVehicle.model_year
        : new Date().getFullYear(),
  });

  const homeChargingKwh =
    totalElectricVehicleKwh * ((100 - publicChargingPercentage) / 100);

  const publicChargingElectricCostPerMonth =
    (totalElectricVehicleKwh *
      (publicChargingCostInCentsPerKwh / 100) *
      (publicChargingPercentage / 100)) /
    12;

  const rateCostCalculator = new RateCostCalculator({
    typicalHomeLoadProfile: homeLoadProfile,
    currentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile: currentChargingPattern.loadProfile,
    isElectricVehicleOwner,
  });

  const gasolineCostCalculator = new GasolineCostCalculator({
    selectedVehicle,
    gasolinePriceInCentsPerGal,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    equivalentMilesPerGallon,
  });

  const rateTotals = calculateRateTotals({
    rates,
    currentRate,
    rateCostCalculator,
    gasolineCostCalculator,
    publicChargingElectricCostPerMonth,
    selectedEnergyCostIds,
    isComparingLowestRateOnly,
    isElectricVehicleOwner,
  });

  const rateTotalsWithAllSelected = calculateRateTotals({
    rates,
    currentRate,
    rateCostCalculator,
    gasolineCostCalculator,
    publicChargingElectricCostPerMonth,
    selectedEnergyCostIds: energyCostTypes.map((t) => t.id),
    isComparingLowestRateOnly,
    isElectricVehicleOwner,
  });

  const chartData = rateComparisonChartData({ rateTotals });
  const CHART_COLORS = {
    chartAxesColor: "#262626",
    chartSavingsColor: "#7DB23F",
    chartLabelColor: "#333333",
    chartLegendColor: "#262626",
  };

  return (
    <section className="container RateComparison">
      <div className="row">
        <div className="col-sm-12 title-area">
          <h1>
            <FormattedMessage
              id="rateAdvisor.title"
              defaultMessage="Rate Advisor"
              description="Rate Advisor"
            />
          </h1>
          <p className="lead">
            <FormattedMessage
              id="rateAdvisor.subtitle"
              defaultMessage="Discover the best electric rate for you by using our rate advisor tool below and exploring other offerings from electric generation suppliers at PA Power Switch."
              description="Rate Advisor Subtitle"
              values={{
                powerswitchlink: (chunks) => (
                  <a
                    href="https://www.papowerswitch.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{textDecoration: 'underline'}}
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </div>

      {isLoading && (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && (
        <div className="row">
          <div className="col-md-3">
            <RateOptionsWizard
              electricVehicles={electricVehicles}
              rates={rates}
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
              utilityName="DLC"
              typicalDriverName="driver"
              mostCommonRateName={rates[0].title}
            />
            <InputPanel
              title="Basic Filters"
              className="mb-4"
              defaultIsCollapsed={false}
            >
              <SelectCurrentRate rates={rates} />
              <SelectRateComparisonVehicle
                electricVehicles={electricVehicles}
                selectedVehicle={selectedVehicle}
              />
              <ToggleIsElectricVehicleOwner />
              <SlideMilesDrivenAnnually />
              <SelectChargingPattern chargingPatterns={chargingPatterns} />
            </InputPanel>
            {isPHEV(selectedVehicle) && (
              <InputPanel
                title="Portion PHEV"
                className="mb-4"
                defaultIsCollapsed={false}
              >
                <SlideElectricMilesPortionForPhev />
              </InputPanel>
            )}
            <InputPanel
              title="Advanced Home"
              className="mb-4"
              defaultIsCollapsed={false}
            >
              <SlideCurrentMonthlyBill />
              <SlideEGSKwhPrice />
            </InputPanel>
            <InputPanel
              title="Advanced Charging"
              className="mb-4"
              defaultIsCollapsed={false}
            >
              <SlideGasolinePrice
                label="Gasoline Price"
                description={(val) => `${FormatAsCents(val / 100)} / gallon`}
              />

              <SlideEquivalentMilesPerGallon />
              <SlidePublicChargingPercentage />
              <SelectPublicChargingCost />
            </InputPanel>
          </div>
          <div className="col-md-9">
            <RateComparisonBanner
              rateTotals={rateTotalsWithAllSelected}
              selectedVehicle={selectedVehicle}
            />
            <RateComparisonChart
              title="Monthly Rate Comparison"
              chartData={chartData}
              className="d-none d-md-block"
              warningMessage={
                rateTotalsWithAllSelected.some((total) => total.home < 0)
                  ? "Warning! The current driving and charging patterns create an EV charging cost greater than the given home monthly bill."
                  : ""
              }
              {...CHART_COLORS}
            >
              <div className="mt-4 mb-4">
                <RateDetails
                  rates={rates}
                  selectedRateDetailsId={userPrefs.get("selectedRateDetailsId")}
                />
              </div>
            </RateComparisonChart>
          </div>
        </div>
      )}
      <div className="mt-4">
        <p className="text-muted">
          <small>
            <FormattedMessage
              id="rate.disclaimer.top"
              defaultMessage="This rate comparison is provided for illustrative purposes only and
            does not constitute a representation or recommendation by Duquesne
            Light Company (DLC) as to what rate is best for you. DLC cannot
            guarantee the accuracy, completeness or usefulness of the estimated
            cost information. DLC expressly disclaims any and all liability for
            any damages of any nature (including direct, indirect, incidental
            and consequential) arising in connection with the use of the
            estimated rate comparison. This rate comparison depicts usage
            charges based on available interval data and Duquesne Light’s
            current Price to Compare for default electric supply. It may not
            include all fees such as local taxes or surcharges."
              description="Rate Disclaimer"
            />
          </small>
        </p>
        <p className="text-muted">
          <small>
            <FormattedMessage
              id="rate.disclaimer.middle"
              defaultMessage="These estimates are based on electricity use from prior months using
            Duquesne Light’s current Price to Compare for default electric
            supply. It does not reflect the rates in effect in prior months, or
            the current or historical rates offered by Electric Generation
            Suppliers (EGSs) for electric supply. As a result, these costs may
            not match historical billed charges. Estimated costs will vary based
            on future energy use and future default service rate prices."
              description="Rate Disclaimer"
            />
          </small>
        </p>
        <p className="text-muted">
          <small>
            <FormattedMessage
              id="rate.disclaimer.bottom"
              defaultMessage="
            The estimates above include distribution, transmission, supply
            charges and surcharges and assume a Residential Service (RS)
            distribution rate class. Therefore, these estimates are not
            reflective of residential customers within a Residential Service
            Heating (RH) or Residential Service Add on Heat Pump (RA)
            distribution rate class."
              description="Rate Disclaimer"
            />
            The estimates above include distribution, transmission, supply
            charges and surcharges and assume a Residential Service (RS)
            distribution rate class. Therefore, these estimates are not
            reflective of residential customers within a Residential Service
            Heating (RH) or Residential Service Add on Heat Pump (RA)
            distribution rate class.
          </small>
        </p>
      </div>
    </section>
  );
};

export default RateComparison;

RateComparison.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
  electricVehicles: PropTypes.array
};
