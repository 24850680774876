import React, { useContext, useState, useEffect } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import useSelectedVehicle from '../../../hooks/useSelectedVehicle';
import { useIntl } from "react-intl";


const SelectRateComparisonVehicle = ({
  electricVehicles,
  selectedVehicle: givenSelectedVehicle,
}) => {

  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const [evOptions, setEvOptions] = useState([]);
  const [makeOption, setMakeOption] = useState("");
  const [modelOption, setModelOption] = useState("");
  const [yearOption, setYearOption] = useState("");

  const fallbackSelectedVehicle = useSelectedVehicle(
    electricVehicles,
    userPrefs.get("vehicleIdForRateComparison")
  );
  let selectedVehicle = givenSelectedVehicle || fallbackSelectedVehicle;
  
  
  useEffect(() => {
    setMakeOption(selectedVehicle.make)
    const modelAndTrim = selectedVehicle.trim
      ? `${selectedVehicle.model} ${selectedVehicle.trim}`
      : `${selectedVehicle.model}`;
    setModelOption(modelAndTrim)
    setYearOption(selectedVehicle.model_year)
  }, [selectedVehicle])

  useEffect(() => {
    let vehicles = electricVehicles;
    setEvOptions(vehicles);
   
  }, [electricVehicles]);
  
  
  
  
  
  const onChangeMake = (e) => {
    const newMakeType = e.target.value;
    setMakeOption(newMakeType);
    setModelOption("");
    setYearOption("");
  };

  const onChangeModelType = (e) => {
    const newModelType = e.target.value;
    const vehicleHandle =
      e.target.options[e.target.selectedIndex].dataset.handle;

    if (newModelType === "Select a Model") {
      setModelOption(newModelType);
      return;
    }
    
    selectedVehicle = evOptions.find(vehicle => {
      return vehicle.handle === vehicleHandle
    })
    if (newModelType === "Battery EV" || newModelType === "Plug in Hybrid EV") {
      userPrefs.set({
      vehicleIdForRateComparison: selectedVehicle.electric_vehicle_id,
    });
    }
    
    
    setModelOption(newModelType);
    setYearOption("");
  };

  const onChangeYear = (e) => {
    const newYear = e.target.value;
    setYearOption(newYear);
    const vehicleHandle =
      e.target.options[e.target.selectedIndex].dataset.handle;

    if (newYear === "Select a Year") {
      setYearOption(newYear);
      return;
    }

    selectedVehicle = evOptions.find((vehicle) => {
      return vehicle.handle === vehicleHandle;
    });
    userPrefs.set({
      vehicleIdForRateComparison: selectedVehicle.electric_vehicle_id,
    });
  };
  const vehicleMakeOptions = (vehicles) => {
    let vehicleMakes = new Set();
    
    vehicles.forEach((vehicle) => {
      if (vehicle) {
        vehicleMakes.add(vehicle.make);
      }
    });


    vehicleMakes = [...vehicleMakes];


    vehicleMakes.sort((a, b) => {
      return a.localeCompare(b);
    });

    return vehicleMakes.map((make) => {
      return (
        <option value={make} key={make}>
          {make}
        </option>
      );
    });
  };

  const vehicleModelOptions = (vehicles, makeOption) => {
    let vehicleModels = new Set();

    if (makeOption) {
      vehicles = vehicles.filter((vehicle) => {
        const modelAndTrim = vehicle.trim
          ? `${vehicle.model} ${vehicle.trim}`
          : `${vehicle.model}`;
        if (vehicle.make === makeOption) {
          if (!vehicleModels.has(modelAndTrim)) {
            vehicleModels.add(modelAndTrim);
            return vehicle;
          }
        }
        return false;
      });
      return vehicles.map((vehicle) => {
        const modelAndTrim = vehicle.trim
          ? `${vehicle.model} ${vehicle.trim}`
          : `${vehicle.model}`;
        return (
          <option value={modelAndTrim} data-handle={vehicle.handle}>
            {modelAndTrim}
          </option>
        );
      });
    }
  };
  const vehicleYearOptions = (vehicles, modelOption) => {
    if (modelOption) {
      vehicles = vehicles.filter((vehicle) =>
        vehicle.display_name?.trim().toLowerCase() ===
            modelOption.toLowerCase()
      );

      vehicles.sort((v1, v2) => v2.model_year - v1.model_year);
      return vehicles.map((vehicle) => {
        return (
          <option value={vehicle.model_year} data-handle={vehicle.handle}>
            {vehicle.model_year}
          </option>
        );
      });
    }
  };

  return (
    <div className="form-group">
      <label>EV Make</label>
      <div className="form-group">
        <select
          className="form-control"
          value={makeOption}
          onChange={onChangeMake}
          aria-label={intl.formatMessage({
            id: "rateComparison.selectMake",
            defaultMessage: "Select a Make",
          })}
        >
          <option defaultValue="">
            {intl.formatMessage({
              id: "rateComparison.selectMake",
              defaultMessage: "Select a Make",
            })}
          </option>
          {vehicleMakeOptions(evOptions)}
        </select>
      </div>
      <label>EV Model</label>
      <div className="form-group">
        <select
          className="form-control"
          value={modelOption}
          onChange={onChangeModelType}
          disabled={makeOption ? null : true}
          aria-label={intl.formatMessage({
            id: "rateComparison.selectModel",
            defaultMessage: "Select a Model",
          })}
        >
          <option defaultValue="">
            {intl.formatMessage({
              id: "rateComparison.selectModel",
              defaultMessage: "Select a Model",
            })}
          </option>
          {vehicleModelOptions(evOptions, makeOption)}
        </select>
      </div>

      <label>EV Year</label>
      <div className="form-group">
        <select
          className="form-control"
          value={yearOption}
          onChange={onChangeYear}
          aria-label={intl.formatMessage({
            id: "rateComparison.selectYear",
            defaultMessage: "Select a Year",
          })}
          disabled={
            modelOption === "" ||
            modelOption === "Plug in Hybrid EV" ||
            modelOption === "Battery EV"
              ? true
              : null
          }
        >
          <option defaultValue="">
            {intl.formatMessage({
              id: "rateComparison.selectYear",
              defaultMessage: "Select a Year",
            })}
          </option>
          {vehicleYearOptions(evOptions, modelOption)}
        </select>
      </div>
    </div>
  );
};

export default SelectRateComparisonVehicle;
