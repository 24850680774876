import React, { useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import { ChevronDown, ChevronUp } from '../../assets/images/icons/Chevron';

const RateDetails = ({ rates }) => {
  const userPrefs = useContext(UserPrefsContext);
  const {
    isViewingRateDetails,
    selectedRateDetailsId
  } = userPrefs.get();

  const selectedRate = rates.find(rate => rate.id === selectedRateDetailsId);
  const SelectedDetailsComponent = (selectedRate || {}).detailsComponent ||
    (() => null);

  return (
    <div>
      <div className="text-center mb-4">
        <button
          className="btn btn-secondary d-flex align-items-center m-auto"
          onClick={() => userPrefs.set({ isViewingRateDetails: !isViewingRateDetails })}
        >
          {isViewingRateDetails && (
            <>
              <span>Hide Rate Details</span>
              {" "}
              <ChevronUp style={{ width: '0.75rem', marginLeft: '0.5rem' }} />
            </>
          )}
          {!isViewingRateDetails && (
            <>
              <span>Show Rate Details</span>
              {" "}
              <ChevronDown style={{ width: '0.75rem', marginLeft: '0.5rem' }} />
            </>
          )}
        </button>
      </div>
      {isViewingRateDetails && (
        <div>
          <div className="d-flex mb-4">
            {rates.map(rate => {
              return (
                <button
                  className={`btn btn-${rate.id === selectedRateDetailsId ? "ae" : "secondary"} mr-2`}
                  key={rate.id}
                  onClick={() => userPrefs.set({ selectedRateDetailsId: rate.id })}
                >
                  {rate.title.join(' ')}
                </button>
              )
            })}
          </div>
          <div>
            <SelectedDetailsComponent />
          </div>
        </div>
      )}
    </div>
  );
};

export default RateDetails;
