import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

import { FormatAsInt } from '../../../../utils/Helpers/Format';
import Range from '../../../../components/shared/InputElements/Range';

const descriptionFn = val => FormatAsInt(val).toString() + " mpg";

const SlideEquivalentMilesPerGallon = ({
  id = "equivalent-miles-per-gallon",
  label = "Conventional Vehicle Efficiency (mpg)",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("equivalentMilesPerGallon")}
      label={label}
      rangeMin={5}
      rangeMax={40}
      rangeStep={1}
      description={description}
      handler={e => userPrefs.set({ equivalentMilesPerGallon: e.target.value })}
      {...rest}
    />
  );
};

export default SlideEquivalentMilesPerGallon;

SlideEquivalentMilesPerGallon.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string
};
