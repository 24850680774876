import chargingPatterns from '../../client_customizations/data/chargingPatterns';
import { dlcRates } from '../../client_customizations/data/rates';
import energyCostTypes from '../../client_customizations/data/energyCostTypes';

const USER_PREF_PRESETS = {
  availability: "released,pre-order,upcoming",
  canTurnInClunker: false,
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  electricMilesPortionForPhev: 60,
  hasOvernightCharger: null,
  gasolinePriceInCentsPerGal: 295,
  hasRegularPowerOutlet: null,
  householdIncome: 75000,
  householdSize: 1,
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  locallyAvailableVehicleFilter: false,
  maxBudget: 30000,
  milesDrivenAnnually: 20000,
  milesDrivenDaily: 30,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "",
  purchaseMethod: "cash",
  salesTax: "0.07",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: "EV2-A",
  taxFilingStatus: "single",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleHandleForIncentives: "",
  vehicleSortDirection: "desc",
  vehicleSortType: "match_score",
  workingZipcode:  `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  zipcode: `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  chargerBudget: 2000,
  chargerCordLength: 25,
  chargerSortType: "price",
  chargerSortDirection:"asc",
  vehicleMakeFilter: "All",
  vehicleAgeFilter: "All",
  vehicleInsuranceYearly: null,
  vehicleIdForRateComparison: "",
  currentMonthlyBill: 100,
  chargingPatternId: (chargingPatterns[0] || {}).id || "",
  currentRateId: (dlcRates[0] || {}).id || "",
  hasSolarAtHome: false,
  equivalentMilesPerGallon: 25,
  publicChargingCostInCentsPerKwh: 20,
  publicChargingPercentage: 0,
  selectedEnergyCostIds: energyCostTypes.map(costType => costType.id),
  isViewingRateDetails: false,
  selectedRateDetailsId: (dlcRates[0] || {}).id || "",
  isComparingLowestRateOnly: true,
  hasEgsSupply: false,
  egsPriceInCentsPerKwh: 6,
  isElectricVehicleOwner: false,

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    wagon: false,
    truck: false
  },

  chargerWifiFilter: {
    yes: false,
    no: false
  },

  chargerTypeFilters: {
    mounted: false,
    portable: false
  },

  chargerFormFactorFilters: {
    "6-20": false,
    "6-30": false,
    "10-30": false,
    "14-30": false,
    "6-50": false,
    "14-50": false,
    "14-60": false,
    "Hardwired": false 
  }
};

export default USER_PREF_PRESETS;
