import React, { useContext } from 'react';
import ButtonGroup from '../../../../components/shared/InputElements/ButtonGroup';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const PatternTitle = ({ pattern }) => (
  <div>
    <b>{pattern.title}</b>
    <br />
    ({pattern.hours})
  </div>
);

const ButtonGroupChargingPatterns = ({
  id = "btn-group-charging-patterns",
  label = "",
  chargingPatterns,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const { chargingPatternId } = userPrefs.get();
  const { image } = chargingPatterns
    .find(pattern => pattern.id === chargingPatternId) || {};

  return (
    <div className="ButtonGroupChargingPatterns">
      <ButtonGroup
        id={id}
        value={chargingPatternId}
        label={label}
        optionNames={chargingPatterns.map(pattern => <PatternTitle pattern={pattern} key={pattern.id} />)}
        optionValues={chargingPatterns.map(pattern => pattern.id)}
        optionDescriptions={chargingPatterns.map(pattern => pattern.description)}
        handler={(e, selected) => userPrefs.set({ chargingPatternId: selected })}
        {...rest}
      />
      {image && <img className="mw-100" src={image} alt=""/>}
    </div>
  )
};

export default ButtonGroupChargingPatterns;
