import {
  HOME_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  GAS_ENERGY_COST_ID,
} from '../constants/';

const calculateRateTotals = ({
  rates,
  currentRate,
  rateCostCalculator,
  gasolineCostCalculator,
  publicChargingElectricCostPerMonth,
  selectedEnergyCostIds,
  isComparingLowestRateOnly,
  isElectricVehicleOwner,
}) => {
  const {
    equivalentGasVehicle: equivalentGasVehicleGasolineCost,
    ev: evGasolineCost,
  } = gasolineCostCalculator.monthlyCosts();

  const rateTotals = [
    currentRate,
    ...rates
  ].map((rate, idx) => {
    const isCurrentUserSituation = idx === 0;

    const {
      home: homeElectricityCost,
      ev: evElectricityCost,
    } = rateCostCalculator.monthlyCosts(rate);

    const gasolineCost = (() => {
      if (!selectedEnergyCostIds.includes(GAS_ENERGY_COST_ID)) return 0;
      if (isCurrentUserSituation && !isElectricVehicleOwner) return equivalentGasVehicleGasolineCost;
      return evGasolineCost;
    })();

    const evCost = (() => {
      if (!selectedEnergyCostIds.includes(EV_ENERGY_COST_ID)) return 0;
      if (isCurrentUserSituation && !isElectricVehicleOwner) return 0;

      return publicChargingElectricCostPerMonth + evElectricityCost;
    })();

    const homeCost = selectedEnergyCostIds.includes(HOME_ENERGY_COST_ID) ?
      homeElectricityCost :
      0;

    const totalMonthlyCost = gasolineCost + evCost + homeCost;

    return {
      id: `${rate.id}${idx === 0 ? "-current" : ""}`,
      rateId: rate.id,
      title: rate.title,
      [HOME_ENERGY_COST_ID]: homeCost,
      monthly: totalMonthlyCost,
      [EV_ENERGY_COST_ID]: evCost,
      [GAS_ENERGY_COST_ID]: gasolineCost,
      annual: totalMonthlyCost * 12,
    };
  });

  const lowestRateTotal = rateTotals.find(total => {
    return total.monthly === Math.min(...rateTotals
      .filter((rateTotal, idx) => idx !== 0)
      .map(t => t.monthly)
    );
  });
  const filteredRateTotals = isComparingLowestRateOnly ?
    rateTotals.filter((rateTotal, idx) => idx === 0 || rateTotal.rateId === lowestRateTotal.rateId) :
    rateTotals

  return filteredRateTotals.map(rateTotal => (
    {
      ...rateTotal,
      isLowest: rateTotal.id === lowestRateTotal.id,
    }
  ));
};

export default calculateRateTotals;
