import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import FuelRangeChart from "../CostsCharts/FuelRangeChart";
import { loadInsuranceData } from "../../functions/vehicle/CostOfOwnership/api/loadInsuranceData";
import { loadMaintenanceData } from "../../functions/vehicle/CostOfOwnership/api/loadMaintenanceData";
import CostToOwnComparison from "../../client_customizations/components/CostCharts/CostToOwnComparison"
import {FormattedMessage} from 'react-intl';
import EVCard from "../EVCard/EVCard";

const VehicleCarousel = ({ electricVehicles }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [selectedEv, setSelectedEv] = useState(1);
  const [firstEv, setFirstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );
  const [isLoading, setIsLoading] = useState(true);
  const [insuranceData, setInsuranceData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

    useEffect(() => {
       // eslint-disable-next-line react-hooks/exhaustive-deps
       if (electricVehicles && electricVehicles.length > 3) {
          loadInsuranceData(electricVehicles[evIndices[selectedEv]].handle, userPrefs, setInsuranceData, setIsLoading);
          const maintenanceCars = [electricVehicles[evIndices[selectedEv]], electricVehicles[evIndices[selectedEv]].equivalent_gas_vehicle];
          if(maintenanceCars) {
              loadMaintenanceData(maintenanceCars, userPrefs, setMaintenanceData, setIsLoading);
          }
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [electricVehicles, userPrefs]);

     if (!electricVehicles || electricVehicles.length <= 3) return null;

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${"evc-card EVCard " +
          (selectedEv === i ? " selected" : "not-selected")}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev} hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div>
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );

  const renderVehicleCostChart = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
      <FuelRangeChart cars={[electricVehicles[evIndices[selectedEv]], electricVehicles[evIndices[selectedEv]].equivalent_gas_vehicle]} />
    </div>
  );

  
  const renderCostToOwnComparison = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
      {!isLoading ? (
       <CostToOwnComparison
              cars={[electricVehicles[evIndices[selectedEv]], electricVehicles[evIndices[selectedEv]].equivalent_gas_vehicle]}
              insuranceData={insuranceData}
              maintenanceData={maintenanceData}
              hasExtraPadding
              displayEvCostBreakdownOption
              forceUserPrefsPresets
       />
       ) : null}
     </div>
  );

  return (
    <section className="container pb-0" id="HomepageVehiclesCarousel">
      <div className="row">
        <div className="col-md-9">
          <h2>
            <FormattedMessage 
                id="homepage.vehicleCarousel.title"
                defaultMessage="There are {vehicleCount} electric vehicles available. Discover yours."
                description="Vehicle Carousel Title"
                values= {{
                  vehicleCount: vehicleCount
                }}
              />
          </h2>
          <p className="lead">
            Compare the average cost of ownership to a similar gas vehicle using
            our calculator.
          </p>
        </div>
        <div className="col-md-3">
          <Link to="/vehicles" style={{margin:"12px"}} className="btn btn-dlc mt-10" role="button">
            <FormattedMessage 
                id="homepage.vehicleCarousel.findEVButton"
                defaultMessage="See all vehicles"
                description="Find EV Button"
              />
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">{renderVehicles}</div>
        <div
          className="col-sm-12 d-none d-md-block"
          style={{ marginTop: "1rem" }}
        >
          {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGET_ENABLED ? (renderVehicleCostChart) : (null)}
          {/* {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED ? (renderMonthlyFuelCostChart) : (null)} */}
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON ? (renderCostToOwnComparison) : (null)}
        </div>
      </div>
      <hr className="mt-5" />
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
