import ElevenPmToSixAm from "./chargingPatterns/11pm-6am.png";
import NinePmToOnePm from "./chargingPatterns/9pm-1pm.png";
import OnePmToNinePm from "./chargingPatterns/1pm-9pm.png";
import AllHours from "./chargingPatterns/allhours.png";

const DST = {
  2019: {
    startHour: 1634,
    endHour: 7346,
  },
};

const dstAdjustedHour = (hour, year) => {
  const { startHour, endHour } = DST[year];
  const isWithinDST = hour >= startHour && hour <= endHour;
  return isWithinDST ?
    (hour + 1) % 24 :
    hour % 24;
};

// NOTE: this is not a complete list of leap years, but is sufficient for the data
// provided
const isLeapYear = year => [2016,2020].includes(year);
const hoursInYear = year => isLeapYear(year) ? 8784 : 8760;

// This value gets scaled to match the user's home charging kWh. We just need it
// to be a float and the RateCostCalculator will handle the rest
const RANDOM_FLOAT = 1.1;

const chargingPatterns = [
  {
    id: 'after-11pm-before-6am',
    title: "Overnight",
    hours: "11 p.m. - 6 a.m.",
    loadProfile: {
      data: (year) => new Array(hoursInYear(year)).fill(null).map((_, idx) => {
        const hour = dstAdjustedHour(idx, year);
        return hour >= 23 || hour < 6 ? RANDOM_FLOAT : 0;
      }),
    },
    description: 'Recommended!',
    image: ElevenPmToSixAm,
  },
  {
    id: 'after-9pm-before-1pm',
    title: "Late Evening to Early Afternoon",
    hours: "9 p.m. - 1 p.m.",
    loadProfile: {
      data: (year) => new Array(hoursInYear(year)).fill(null).map((_, idx) => {
        const hour = dstAdjustedHour(idx, year);
        return hour >= 21 || hour < 13 ? RANDOM_FLOAT : 0;
      })
    },
    image: NinePmToOnePm,
  },
  {
    id: 'after-1pm-before-9pm',
    title: "Afternoon to Evening",
    hours: "1 p.m. - 9 p.m.",
    loadProfile: {
      data: (year) => new Array(hoursInYear(year)).fill(null).map((_, idx) => {
        const hour = dstAdjustedHour(idx, year);
        return hour >= 13 && hour < 21 ? RANDOM_FLOAT : 0;
      })
    },
    image: OnePmToNinePm,
  },
  {
    id: 'all-hours',
    title: 'All hours',
    hours: 'unpredictable',
    loadProfile: {
      data: (year) => new Array(hoursInYear(year)).fill(null).map(() => RANDOM_FLOAT)
    },
    image: AllHours,
  }
];

export default chargingPatterns;
