import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const crypto = require('crypto');

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSaving: false,
      goal: "",
      achievedGoal: null,
      wouldRecommend: null,
      suggestions: ""
    };

    this.toggle = this.toggle.bind(this);
    this.onChangeGoal = this.onChangeGoal.bind(this);
    this.onChangeAchievedGoal = this.onChangeAchievedGoal.bind(this);
    this.onChangeWouldRecommend = this.onChangeWouldRecommend.bind(this);
    this.onChangeSuggestions = this.onChangeSuggestions.bind(this);
    this.isSubmittable = this.isSubmittable.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onChangeGoal(e) {
    this.setState({ goal: e.target.value });
  }

  onChangeAchievedGoal(e) {
    this.setState({ achievedGoal: e.currentTarget.value });
  }

  onChangeWouldRecommend(e) {
    this.setState({ wouldRecommend: e.currentTarget.value });
  }

  onChangeSuggestions(e) {
    this.setState({ suggestions: e.target.value });
  }

  isSubmittable() {
    const {
      isSaving,
      goal,
      achievedGoal,
      wouldRecommend,
      suggestions
    } = this.state;

    if (isSaving) {
      return false;
    }

    // If the user has filled anything out, form is submittable
    return (
      goal.length ||
      achievedGoal !== null ||
      wouldRecommend !== null ||
      suggestions.length
    );
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.isSubmittable()) {
      return;
    }

    this.setState({ isSaving: true });

    this.recordFeedback();
  }

  recordFeedback() {
    let url = new URL(
      process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_FEEDBACK_PROD_BACKEND_HOST : process.env.REACT_APP_FEEDBACK_DEV_BACKEND_HOST
    );

    // let ip = this.state.ipData ? this.state.ipData.ip : null

    let data = {
      uuid: this.props.uuid,
      client_ip: this.props.ip,
      current_path: window.location.href,
      goal: this.state.goal,
      goal_achieved: this.state.achievedGoal,
      would_recommend: this.state.wouldRecommend,
      suggestions: this.state.suggestions
    };

    // Remove null data
    Object.keys(data).forEach(key => data[key] == null && delete data[key]);

    const payload = Buffer.from(JSON.stringify(data)).toString('base64');
    const feedback = {
      service: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_FEEDBACK_PROD_SERVICE_ID : process.env.REACT_APP_FEEDBACK_DEV_SERVICE_ID,
      payload: [ payload ],
      checksum: "-Sha1-" + crypto.createHash("sha1").update(payload, "utf-8").digest("hex")
    }

    window
      .fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"        
        },
        body: JSON.stringify(feedback)
      })
      .then(data => {
        this.setState({
          isOpen: false,
          isSaving: false,
          goal: "",
          achievedGoal: null,
          wouldRecommend: null,
          suggestions: ""
        });
      });
  }

  render() {
    const { isOpen, isSaving, wouldRecommend, suggestions } = this.state;

    return (
      <div>
        <button type="button" className="btn btn-dlc" onClick={this.toggle}>
          Submit Feedback
        </button>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Feedback</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="">
                  Would you recommend this tool to a friend?
                </label>
                <div className="form-check">
                  <label
                    className="form-check-label"
                    htmlFor="wouldRecommend-true"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="feedback_would_recommend"
                      id="wouldRecommend-true"
                      value="true"
                      checked={wouldRecommend === "true"}
                      onChange={this.onChangeWouldRecommend}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <label
                    className="form-check-label"
                    htmlFor="wouldRecommend-false"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="feedback_would_recommend"
                      id="wouldRecommend-false"
                      value="false"
                      checked={wouldRecommend === "false"}
                      onChange={this.onChangeWouldRecommend}
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="feedback_suggestions">
                  How would you improve this tool?
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="feedback_suggestions"
                  id="feedback_suggestions"
                  value={suggestions}
                  onChange={this.onChangeSuggestions}
                />
              </div>

              <button
                type="submit"
                className="btn btn-dlc"
                disabled={!this.isSubmittable()}
              >
                {isSaving ? "Submitting..." : "Submit"}
              </button>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default FeedbackModal;

FeedbackModal.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string
};
