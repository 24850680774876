import React, { useContext } from 'react';
import ButtonGroup from '../../../../components/shared/InputElements/ButtonGroup';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const ButtonGroupRates = ({
  id = "btn-group-rates",
  label = "",
  rates,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  const defaultRateOption = <span>
    {Array.isArray(rates[0].title) ? rates[0].title.join(" ") : rates[0].title}
    <br/>
    (Default)
  </span>;

  const otherOptions = rates
    .filter((rate, idx) => idx !== 0)
    .map(rate => (Array.isArray(rate.title) ? rate.title.join(" ") : rate.title));

  const optionNames = [
    defaultRateOption,
    ...otherOptions
  ];

  return (
    <div className="ButtonGroupRates">
      <ButtonGroup
        id={id}
        value={userPrefs.get("currentRateId")}
        label={label}
        optionNames={optionNames}
        optionValues={rates.map(rate => rate.id)}
        handler={(e, selected) => userPrefs.set({ currentRateId: selected })}
        {...rest}
      />
    </div>
  )
};

export default ButtonGroupRates;
