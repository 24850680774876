import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData"
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";
// import {FormattedMessage} from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData([
    { field: "grantor", value: "Federal", count: 1 },
    { field: "grantor_type", value: "State", count: 2 },
    { field: "grantor_type", value: "Power Supplier", count: 3 }
  ], incentives)

  return (
    <section className="container pb-0" id="HomepageIncentives">
      <div className="row">
        <div className="col-sm-12">
          <h2>Explore potential EV incentives and tax credits</h2>
          <p className="lead">
            See how much you could save getting behind the wheel of an EV,
            whether you are buying or leasing. Incentives are personalized for {process.env.REACT_APP_COMPANY_NAME_ABRV} customers.
          </p>
        </div>
      </div>
      <IncentiveCatalog incentives={incentives} category="all" />
      <div className="row mt-4">
        <div className="col-sm-12">
          <Link to="/incentives" className="btn btn-dlc" role="button">
            Personalize Incentives
          </Link>
        </div>
      </div>
      <hr className="mt-5" />
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
