import React from "react";
import PropTypes from "prop-types";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { FormatAsDollars } from "../../../../utils/Helpers/Format"
import { useIntl } from 'react-intl';

const CostToOwnComparisonChart = ({
    carNames,
    carsCosts,
    equivalentGasVehicleCosts,
    displayFuelOnly,
    displayEvCostBreakdownOption,
    displayEvCostBreakdown
}) => {
  let chartData = {};

  const intl = useIntl()

  if (displayFuelOnly) {
    chartData = {
      labels: carNames,
      datasets: [
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.electricity", defaultMessage: "Electricity"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.electricity.total
            )
          })
        },
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.gasoline", defaultMessage: "Gasoline"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.gasoline.total
            )
          })
        },
      ]
    };
  } else if (!displayEvCostBreakdown) {
    chartData = {
      labels: carNames,
      datasets: [
        {
          backgroundColor: [`#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`, `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`],
          data: carsCosts.map(carCost => {
            return (
              carCost.vehicle.total +
              carCost.electricity.total +
              carCost.gasoline.total +
              carCost.maintenance.total +
              carCost.insurance.total
            )
          })
        }
      ]
    };
  } else {
    chartData = {
      labels: carNames,
      datasets: [
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.vehicle", defaultMessage: "Vehicle net Incentives, Resale"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_NET}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.vehicle.total
            )
          })
        },
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.electricity", defaultMessage: "Electricity"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.electricity.total
            )
          })
        },
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.gasoline", defaultMessage: "Gasoline"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.gasoline.total
            )
          })
        },
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.maintenance", defaultMessage: "Maintenance"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_MAINTENANCE}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.maintenance.total
            )
          })
        },
        {
          label: intl.formatMessage({ id: "graph.costOfOwnership.chart.insurance", defaultMessage: "Insurance"}),
          backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_INSURANCE}`,
          data: carsCosts.map(carCost => {
            return (
              carCost.insurance.total
            )
          })
        }
      ]
    };
  }
  
  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          value = FormatAsDollars(value);
          return " " + label + ": " + value;
        },
        title: function(tooltipItems, data) {
          let total = tooltipItems.reduce(function(acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ": " + FormatAsDollars(total);
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function(value) {
              value = FormatAsDollars(value);
              return value;
            },
            beginAtZero: true
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700
            }
          }
        }
      ]
    },
    legend: {
      position: "bottom",
      onClick: function(e) {
        e.stopPropagation();
      },
      display: displayEvCostBreakdown
    },
    layout: {
      padding: {
        right: 75
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach(dataset => {
              sum += dataset.data[ctx.dataIndex];
            });
            return FormatAsDollars(sum);
          } else {
            return "";
          }
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700
        }
      }
    }
  };

  return (
    <HorizontalBar
      data={chartData}
      height={212}
      type="horizontalBar"
      options={options}
    />
  );
};

export default CostToOwnComparisonChart;

CostToOwnComparisonChart.propTypes = {
  carNames: PropTypes.array,
  carsCosts: PropTypes.array,
  displayEvCostBreakdownOption: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayFuelOnly: PropTypes.bool
};