import energyCostTypes from '../data/energyCostTypes';

const rateComparisonChartData = ({ rateTotals }) => {
  return {
    datasets: energyCostTypes.map(({ id, title, backgroundColor }) => {
      return {
        id,
        label: title,
        data: rateTotals.map((rateTotal) => rateTotal[id]),
        barPercentage: 0.5,
        categoryPercentage: 1,
        backgroundColor
      };
    }),
    labels: [
      ["Current", ...rateTotals[0].title],
      ...rateTotals.filter((rate, i) => i !== 0).map((rate) => rate.title),
    ],
  }
}

export default rateComparisonChartData;
