import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const SelectCurrentRate = ({ rates }) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Select
      id="rate-comparison-current-rate-id"
      value={userPrefs.get("currentRateId")}
      handler={(e) => userPrefs.set({ currentRateId: e.target.value })}
      optionNames={rates.map(rate => rate.title.join(" "))}
      optionValues={rates.map(rate => rate.id)}
      label="Current Rate"
    />
  );
};

export default SelectCurrentRate;
