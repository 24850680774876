import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

const FAQ = ({ ip, uuid }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_FAQ_TITLE;
  });

  const [showQuestion, setShowQuestion] = useState(null);

  const renderAutomatedAnswer = (answer, list) => (
    <div style={{ backgroundColor: "white", padding: "1rem" }}>
      {answer.split("\n").map((item, i) => {
        return <p key={i}>{item}</p>;
      })}
      {!list ? null : (
        <ol>
          {list.map((item, i) => {
            return <li key={i}>{item}</li>;
          })}
        </ol>
      )}
    </div>
  );

  const renderQuestionWithAutomatedAnswer = (question, answer, list) => (
    <div
      onClick={() => setShowQuestion(question)}
      onKeyPress={() => setShowQuestion(question)}
      className="clickable-faq"
      tabIndex="0"
    >
      <p className="h4 mt-0">{question}</p>
      {showQuestion === question && renderAutomatedAnswer(answer, list)}
    </div>
  );

  const renderQuestion = (question, answer) => (
    <div
      onClick={() => setShowQuestion(question)}
      onKeyPress={() => setShowQuestion(question)}
      className="clickable-faq"
      tabIndex="0"
    >
      <p className="h4 mt-0">{question}</p>
      {showQuestion === question && answer}
    </div>
  );

  return (
    <section className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Duquesne Light EV 101</h1>
          <div className="SimpleCopyPageBody">
            <h2 className="mt-4" style={{ color: "#9E5F00" }}>
              Vehicles
            </h2>

            {renderQuestionWithAutomatedAnswer(
              "What is an Electric Vehicle (EV)?",
              "An electric vehicle is any vehicle that can drive on electricity derived from a power plug. An all-electric vehicle (sometimes called a battery electric vehicle or a “BEV”) drives solely on power from the plug."
            )}

            {renderQuestionWithAutomatedAnswer(
              "What types of electric vehicles are available?",
              "Two kinds of electric vehicles are available:",
              [
                "Battery Electric Vehicle (BEV) relies exclusively on a battery to power the car.",
                "Plug-In Hybrid Electric Vehicles (PHEV) uses both electricity and gasoline. When driving, they use battery power and switch to gasoline when the battery is empty."
              ]
            )}

            {renderQuestionWithAutomatedAnswer(
              "Can electric vehicles drive far enough to be practical?",
              "Most battery electric cars have a range of at least 85-110 miles, although this is quickly growing. Very few drivers travel this far on a daily basis. For the infrequent occasions when a long-distance drive is needed, the drive can be done by charging on the road, with access to vehicles in car-share services, or by renting or borrowing another vehicle. And Plug-In Hybrid Electric Vehicles have no electric range limitation since the switch to gasoline when the battery is empty."
            )}

            {renderQuestionWithAutomatedAnswer(
              "Is the quiet nature of EVs a hazard?",
              "EVs aren’t silent, and at parking-lot speeds they make as much noise from various fans, pumps and tire noise as most modern internal-combustion engine vehicles. At high speeds, the wind and tire noise is comparable to any car."
            )}

            {renderQuestionWithAutomatedAnswer(
              "Are EVs dependable?",
              "Yes. EVs are among the most dependable vehicles on the market. They will last as long as or longer than gasoline automobiles, with less regular maintenance required."
            )}

            {renderQuestionWithAutomatedAnswer(
              "What maintenance do EVs require?",
              "Since there are significantly fewer moving parts in an EV compared to a traditional vehicle, less ongoing preventative maintenance is needed. They require no oil changes, tune-ups, or new spark plugs. Brake life is extended on EVs since the motor is used to slow the car, recapturing the kinetic energy and storing it back in the battery. Many automakers also offer warranties on the batteries."
            )}

            {renderQuestionWithAutomatedAnswer(
              "EVs are more expensive up front, but are they cheaper to own in the long run?",
              "EVs are cheaper to operate than gasoline-fueled vehicles due to lower fuel and maintenance costs. In the state of Pennsylvania, an “e-gallon” of electricity is less than half the cost of a gallon of gas. Over the lifetime of the vehicle, EVs are less expensive than gasoline-fueled vehicles."
            )}

            {renderQuestionWithAutomatedAnswer(
              "Are there any environmental gains if we just switch from petroleum to electricity to power our cars?",
              "Based on the average electricity generation mix in the state of Pennsylvania, EVs emit about one third the amount of greenhouse gas emissions of conventional vehicles and reduce other emissions, including nitrogen oxides (NOx) and particulates. The carbon and air quality benefits will grow as electricity generation continues to get even cleaner."
            )}

            <h2 className="mt-5" style={{ color: "#9E5F00" }}>
              Charging
            </h2>

            {renderQuestion(
              "How long does it take to charge? How would I charge my EV at home?",
              <div style={{ backgroundColor: "white", padding: "1rem" }}>
                <p>
                  It depends on the size of the battery, how depleted your
                  battery is, and what level of charging station you are
                  using. It is helpful to think of this not as how long it
                  takes to charge, but how many miles of charge you can get
                  every hour. There are two ways to charge at home:
                </p>
                <ol>
                  <li>
                    Level 1 uses the cord provided with every EV; it plugs in
                    a standard 120V wall outlet in a garage or on the outside
                    of a home and provides 3-5 miles per hour of charge.
                  </li>
                  <li>
                    Level 2 uses a 240V circuit such as a clothes dryer and
                    provides around 25 miles per hour of charging. It requires
                    a specially installed charging station and may require an
                    upgrade to your electrical panel so hiring an electrical
                    contractor is required.
                  </li>
                </ol>
              </div>
            )}

            {renderQuestion(
              "How can I get charging stations installed at my condo or apartment building?",
              <div style={{ backgroundColor: "white", padding: "1rem" }}>
                <p>
                  Start with{" "}
                  <a
                    href="https://duquesnelight.com/docs/default-source/pdf-library/dlc_mud-charging-checklist-finalelectronicvehicle_08292017.pdf?sfvrsn=2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this guide
                  </a>{" "}
                  or visit the US Department of Energy’s website on{" "}
                  <a
                    href="https://afdc.energy.gov/fuels/electricity_charging_multi.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EV charging at multi-unit dwellings
                  </a>
                  .
                </p>
              </div>
            )}

            {renderQuestionWithAutomatedAnswer(
              "How would I charge my electric car on the go?",
              "Most EV drivers find that they get over 80% of their charging needs completed at home, but sometimes you’ll want to “top off” while you are out. Level 1 and 2 are available at workplaces, retail businesses and other locations around the Pittsburgh region. In addition, a faster option called DC Fast Charge is available for charging on the go. There are three types, each compatible with specific cars:",
              [
                "Combined Charging System (CCS), up to 65 miles in 20 minutes (e.g. BMW i3, VW eGolf, or Bolt EV)",
                "CHAdeMO up to 67 miles in 30 minutes (e.g. Nissan LEAF or Kia Soul EV)",
                "Tesla Supercharger up to 130 miles in 20 minutes"
              ]
            )}

            {renderQuestionWithAutomatedAnswer(
              "How much does it cost to charge my electric car? How does this compare with gasoline?",
              "Just like with gasoline, fuel costs for EVs will vary based on mileage and use. EVs are cheaper than gasoline vehicles to fuel because electricity is less expensive than gasoline and EVs are more efficient than gasoline vehicles. For example, a 2019 Nissan Leaf has a fuel economy of 31 kWh/100 miles, which is the same as 108 miles per gallon (mpg). Assuming the vehicle drives 15,000 miles a year, annual electricity cost for the 2019 Nissan Leaf (using DLC rates), is $350. This is about $30 a month. It is also important to consider fuel price stability; while gasoline prices fluctuate frequently, electricity rates are set on an annual basis."
            )}

            {(
              <div
                onClick={() => setShowQuestion('tou-rate')}
                onKeyPress={() => setShowQuestion('tou-rate')}
                className="clickable-faq"
                tabIndex="0"
              >
                <p className="h4 mt-0">
                  Does Duquesne Light offer a time of use rate, or a special price to charge an EV?
                </p>
                {showQuestion === 'tou-rate' && (
                  <div style={{ backgroundColor: "white", padding: "1rem" }}>
                    <p>
                      We’re now offering time-based electricity supply rates during peak, off-peak, and super off peak time periods for our residential customers who are driving electric and our commercial customers who are driving electric or are hosting an electric vehicle charging station
                    </p>
                    <ul>
                      <li>
                        During off-peak and super off-peak periods, electricity supply rates are lower than DLC’s standard default service supply rate and, during the peak time period, the rate is higher.
                      </li>
                      <li>
                        The rates apply to all of the electricity usage at your home or business, not only to your EV charging.
                      </li>
                      <li>
                        If you’re able to shift the majority of your electricity consumption to off-peak and super off-peak times, such as overnight, this rate might be right for you.
                      </li>
                    </ul>
                    <p className="mb-0">
                      Find more information about our
                      {" "}
                      <a
                        href="https://duquesnelight.com/energy-money-savings/electric-vehicles/wholehome-ev-rate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        WholeHome EV Rate
                      </a>
                      {" "}
                      and
                      {" "}
                      <a
                        href="https://duquesnelight.com/energy-money-savings/electric-vehicles/business-ev-rate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Business EV Rate
                      </a>
                      {" "}
                      by visiting our
                      {" "}
                      <a
                        href="https://duquesnelight.com/energy-money-savings/electric-vehicles"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        website
                      </a>
                      , including eligibility, current rates, answers to frequently asked questions, and more.
                    </p>
                  </div>
                )}
              </div>
            )}

            {renderQuestionWithAutomatedAnswer(
              "How does charging at home compare to other common household appliances?",
              "The power draw for an electric furnace is 10,000 Watts, a water heater is 4,500 Watts, and an air conditioner is 3,500 Watts. Level 2 charging is 7,200 Watts and Level 1 charging is 1,400 Watts. While everyone’s EV charging will differ, according to the US Department of Energy, annual energy consumption for a typical household shows that home heating consumes by far the most energy (11,300 kW-hrs) followed by water heating (4,700 kW-hrs) and charging an EV (2,800 kW-hrs)."
            )}

            <h2 className="mt-5" style={{ color: "#9E5F00" }}>
              Learn More
            </h2>
            <div className="p-3">
              <p>
                <a
                  href="http://www.pgh-cleancities.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pittsburgh Region Clean Cities
                </a>
                <br />
                Local non-profit working to reduce petroleum use in
                transportation.
              </p>
              <p>
                <a
                  href="https://www.dep.pa.gov/Business/Energy/OfficeofPollutionPrevention/State-Energy-Plan/Pages/Drive-Electric-PA-Coalition.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Drive Electric Pennsylvania Coalition
                </a>
                <br />
                Statewide coalition that formed to develop and execute the{" "}
                <a
                  href="http://files.dep.state.pa.us/Energy/OfficeofPollutionPrevention/StateEnergyProgram/PAEVRoadmap.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'underline'}}
                >
                  Pennsylvania EV Roadmap
                </a>
                .
              </p>
              <p>
                <a
                  href="https://www.dep.pa.gov/Citizens/GrantsLoansRebates/Alternative-Fuels-Incentive-Grant/pages/default.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pennsylvania Department of Environmental Protection AFIG
                  Program
                </a>
                <br />
                State government website describing alternative fuel vehicle
                incentive grant program.
              </p>
              <p>
                <a
                  href="http://www.depgis.state.pa.us/drivingpaforward/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pennsylvania Department of Environmental Protection Driving
                  PA Forward Program
                </a>
                <br />
                State government website describing $118.5M emission-reduction
                grant and rebate program.
              </p>
              <p>
                <a
                  href="https://pluginamerica.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Plug In America
                </a>
                <br />
                National non-profit focused on EV education and outreach.
              </p>
              <p>
                <a
                  href="http://www.afdc.energy.gov/fuels/electricity.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  U.S. Department of Energy Alternative Fuels Data Center
                </a>
                <br />
                Federal government website that provides information and tools
                to help reduce the use of petroleum.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;

FAQ.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string
};
