const archetypeVehicles = [
  {
    // Use a giant number as an ID just to make sure this doesn't collide with
    // vehicles from the API
    electric_vehicle_id: 1000000000000,
    electric_efficiency: 33,
    fuel: 'bev',
    fossil_fuel_efficiency: 0,
    equivalent_gas_vehicle: {
      fossil_fuel_efficiency: 25,
    },
    make: "Typical",
    model: "Battery EV",
    model_year: new Date().getFullYear(),
  },
  {
    electric_vehicle_id: 1000000000001,
    electric_efficiency: 30,
    fuel: 'phev',
    fossil_fuel_efficiency: 25,
    equivalent_gas_vehicle: {
      fossil_fuel_efficiency: 25,
    },
    make: "Typical",
    model: "Plug in Hybrid EV",
    model_year: new Date().getFullYear(),
  }
]

export default archetypeVehicles;
