import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import logo from "../../../assets/images/dlc-logo.85f4aa2c.png"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';

const Header = ({ page, activePage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="https://duquesnelight.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "116px" }}
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>Home</span>
                  </Link>
                </NavItem>
              ) : null}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={activePage === "vehicles" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="electricVehicles"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-new-vehicles`}
                    title={page === "vehicles" ? "active" : null}
                    tag={NavLink}
                    to={"/vehicles"}
                  >
                    <FormattedMessage
                      id="NewEvs"
                      defaultMessage="New EVs"
                      description="New EVs Header"
                    />
                  </DropdownItem>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-used-vehicles`}
                    title={page === "vehicles" ? "active" : null}
                    tag={NavLink}
                    to={"/used-vehicles"}
                  >
                    <FormattedMessage
                      id="preOwnedEVs"
                      defaultMessage="Pre-Owned EVs"
                      description="Pre-Owned EVs Header"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "map" ? "Active Page" : null}
                    to="/charging-stations"
                  >
                    <span>Charging Stations</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={
                      page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK
                        ? "Active Page"
                        : null
                    }
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "dealers" ? "Active Page" : null}
                    to="/dealers"
                  >
                    <span>Dealers</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "ev101" ? "Active Page" : null}
                    to="/ev101"
                  >
                    <span>EV 101</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_RATES_ENABLED && (
                <NavItem>
                  <NavLink
                    title={page === "rates" ? "Active Page" : null}
                    to="/rates"
                  >
                    <span>{process.env.REACT_APP_PAGES_RATES_TITLE}</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
