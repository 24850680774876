import React, { useContext } from "react";
import chartOptions from "./chartOptions";
import { Bar } from "react-chartjs-2";
import Checkbox from '../../../components/shared/InputElements/Checkbox';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import energyCostTypes from '../../data/energyCostTypes';
import './RateComparisonChart.scss';

const RateComparisonChart = ({
  chartAxesColor,
  chartData,
  chartLabelColor,
  chartLegendColor,
  chartSavingsColor,
  children,
  className,
  title,
  warningMessage,
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const {
    selectedEnergyCostIds,
    isComparingLowestRateOnly,
  } = userPrefs.get();

  const options = chartOptions({
    shouldShowSavingsAmount: selectedEnergyCostIds.length === energyCostTypes.length,
    savingsColor: chartSavingsColor,
    labelColor: chartLabelColor,
    legendColor: chartLegendColor,
    axesColor: chartAxesColor,
  });

  const handleClickDataset = (id) => {
    const current = userPrefs.get("selectedEnergyCostIds");
    userPrefs.set({
      selectedEnergyCostIds: current.includes(id) ?
        current.filter(existingId => existingId !== id) :
        [...current, id]
    });
  };

  const filteredChartData = {
    ...chartData,
    datasets: chartData.datasets.filter(dataset => selectedEnergyCostIds.includes(dataset.id)),
  };

  const { datasets = [] } = chartData;
  const hasMultipleRates = datasets[0].data && datasets[0].data.length > 1

  return (
    <div className={`RateAdvisorChart ${ className }`}>
      {warningMessage && (
        <div className="alert alert-danger mb-0 rounded-0 border-bottom-0">
          {warningMessage}
        </div>
      )}
      <div className="chart-body">
        <div className="d-flex">
          <div className="mr-auto">
            <h2 className="title">{title}</h2>
            {hasMultipleRates && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="toggle-isComparingLowestRateOnly"
                  checked={isComparingLowestRateOnly}
                  onChange={() => userPrefs.set({isComparingLowestRateOnly: !isComparingLowestRateOnly})}
                />
                <label className="custom-control-label" htmlFor="toggle-isComparingLowestRateOnly">
                  Compare Lowest Rate Only
                </label>
              </div>
            )}
          </div>
          <div>
            {chartData.datasets.map((dataset) => {
              return (
                <div key={dataset.id} className="d-inline-block mr-2">
                  <Checkbox
                    key={dataset}
                    id={dataset.id}
                    value={selectedEnergyCostIds.includes(dataset.id)}
                    handler={() => handleClickDataset(dataset.id)}
                    label={dataset.label}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Bar data={filteredChartData} options={options} />
        {children}
      </div>
    </div>
  );
};

export default RateComparisonChart;
