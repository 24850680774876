import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./usedEVs.scss";

import UsedEVCatalog from "../../components/UsedEVCatalog/UsedEVCatalog"
import MatchScoreOptions from "../../components/MatchScoreOptions/MatchScoreOptions"
import EvSortControls from "../../components/EvSortControls/EvSortControls"

import EVFilterControls from "../../components/EVFilterControls/EVFilterControls"
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent"
import { NavLink } from "react-router-dom";

import {FormattedMessage, useIntl} from 'react-intl';

const UsedEVs = ({ usedElectricVehicles, ip, uuid, page }) => {
  const intl = useIntl() 

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_USED_VEHICLES_TITLE;
  });

  const evToggle = (page) => {
    return (
      <div className="ev-toggle">
        <NavLink
          title={page === "vehicles" ? "Active Page" : null}
          to="/vehicles"
        >
          <span>
            <FormattedMessage
              id="new"
              defaultMessage="New"
              description="Electric Vehicles Toggle"
            />
          </span>
        </NavLink>
        <NavLink
          title={page === "vehicles" ? "Active Page" : null}
          to="/used-vehicles"
        >
          <span>
            <FormattedMessage
              id="preOwned"
              defaultMessage="Pre-Owned"
              description="Electric Vehicles Toggle PreOwned"
            />
          </span>
        </NavLink>
      </div>
    );
  };

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      {evToggle(page)}
      <MatchScoreOptions />
      <br />
      <EVFilterControls vehicles={usedElectricVehicles} usedEvs={true} />
    </>
  );


  return (
    <section className="container" id="EVs">
      <div className="row mb-3">
        <div className="col-sm-12">
          <h1>
            <FormattedMessage 
              id="usedElectricVehicles"
              defaultMessage="Used Electric Vehicles"
              description="Used Electric Vehicles"
            />
          </h1>
          <p className="lead">
            <FormattedMessage 
              id="evs.welcomeSub"
              defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
              description="Description of Colton Recharged"
              values= {{
                lineBreak: <br />
              }}
            />
            </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={intl.formatMessage ? intl.formatMessage({ id: "evs.buttonMatchScoreAndFilters", defaultMessage: "Match Score and Filters"}) : "Match Score and Filters"}
            titleText={intl.formatMessage ? intl.formatMessage({ id: "evs.matchScoreAndFilters", defaultMessage: "Refine Match Score and Filters"}) : "Refine Match Score and Filters"}
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <EvSortControls />
              </div>
            </div>
          </div>
          <UsedEVCatalog
            vehicles={usedElectricVehicles}
            hasEvDetails
            hasIncentivesAndMatchScore
          />
        </div>
      </div>
    </section>
  );
};

export default UsedEVs;

UsedEVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};