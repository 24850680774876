import calcTotalCostOfOwnership from "../../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership";
// IMPORT ASSUMPTIONS HERE AND TOGGLE HERE
import {
  SALES_TAX,
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS";

const getCarCostOfOwnership = (vehicle, userPrefs, insuranceData, maintenanceData, forceUserPrefsPresets) => {
  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  return calcTotalCostOfOwnership(
    vehicle,
    get("purchaseMethod"),
    get("monthsOfOwnership"),
    get("milesDrivenAnnually"),
    get("interestRateAsBasisPoints"),
    get("electricMilesPortionForPhev"),
    get("gasolinePriceInCentsPerGal"),
    get("includeResaleValue"),
    process.env.REACT_APP_SLIDE_SALES_TAX
      ? get("salesTax")
      : SALES_TAX.value,
    insuranceData,
    maintenanceData
  );
};

export default getCarCostOfOwnership;
