import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import MapStyles from "./MapStyles";
import ChargingStationsMapMarker from '../ChargingStationsMapMarker/ChargingStationsMapMarker';

const DEFAULT_CENTER = {
  lat: 37.7916858,
  lng: -122.397855
};
const INITIAL_ZOOM = 15;
const K_HOVER_DISTANCE = 30;
const API_KEY = process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_PROD_GOOGLE_MAP_API_KEY : process.env.REACT_APP_DEV_GOOGLE_MAP_API_KEY

const isReactSnapping = () => navigator.userAgent === "ReactSnap";

const BaseGoogleMapReact = ({
  center,
  children,
  onHoverOnStation,
  onHoverOffStation,
  onClickMarker,
  chargingStations,
  selectedStation,
  ...rest
}) => {
  const hoverProps = {
    ...(onHoverOnStation && {
      onChildMouseEnter: (key, childProps) => {
        onHoverOnStation(childProps.station.id);
      }
    }),
    ...(onHoverOffStation && {
      onChildMouseLeave: onHoverOffStation
    })
  };

  return isReactSnapping() ? null : (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: API_KEY,
        libraries: "places"
      }}
      center={center || DEFAULT_CENTER}
      defaultZoom={INITIAL_ZOOM}
      hoverDistance={K_HOVER_DISTANCE}
      options={{ styles: MapStyles }}
      yesIWantToUseGoogleMapApiInternals
      {...hoverProps}
      {...rest}
    >
      {(chargingStations || []).map((station, i) => (
        <ChargingStationsMapMarker
          key={station.id}
          station={station}
          selectedStation={selectedStation}
          lat={station.latitude}
          lng={station.longitude}
          onClick={onClickMarker}
        />
      ))}
      {children}
    </GoogleMapReact>
  );
};

BaseGoogleMapReact.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  children: PropTypes.node,
  onClickMarker: PropTypes.func,
  onHoverOnStation: PropTypes.func,
  onHoverOffStation: PropTypes.func,
  chargingStations: PropTypes.array,
  selectedStation: PropTypes.object
};

BaseGoogleMapReact.defaultProps = {
  chargingStations: [],
  center: DEFAULT_CENTER,
  onClickMarker: () => {}
};

export default BaseGoogleMapReact;
