import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageIncentives from "./components/HomepageIncentives";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehiclesCarousel from "../../../components/VehicleCarousel/VehicleCarousel"
import ChargingMap from "../../../components/LocationMap/ChargingMap";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import FeedbackModal from "../../../components/FeedbackModal/FeedbackModal"

const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  ip,
  uuid
}) => {

  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  }

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_TITLE;
    setElectricVehicles();
  });

  const renderDetails = electricVehicles && incentives ?
    (
      <>
        <VehiclesCarousel
          electricVehicles={getLocalVehicles(vehicles)}
        />
        <HomepageIncentives incentives={incentives} />
      </>
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
      <HomepageBigPromise
        homePageBannerImage={homePageBannerImage}
        electricVehicles={getLocalVehicles(vehicles)}
      />
      {renderDetails}
      <div id="HomepageChargingStations">
        <section className="container ev-charging-stations">
          <h2>Charging Stations</h2>
          <ChargingMap 
            userLocation={userLocation}
          />
          <hr className="mt-5" />
        </section>
      </div>
      <section className="container pb-5">
        <div className="row">
          <div className="col-sm-9">
            <p className="h2">
              Please provide feedback to help improve the electric vehicle
              guide.
            </p>
          </div>
        </div>
        <FeedbackModal ip={ip} uuid={uuid} />
      </section>
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
