import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Range from '../../../../components/shared/InputElements/Range';

const SlideCurrentMonthlyBill = () => {
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Range
      id="rate-comparison-current-monthly-bill"
      value={userPrefs.get("currentMonthlyBill")}
      handler={(e) => userPrefs.set({currentMonthlyBill: e.target.value})}
      label="Home Monthly Electric Bill"
      rangeMin={10}
      rangeMax={600}
      rangeStep={10}
      description={val => `$${val} / month`}
    />
  );
};

export default SlideCurrentMonthlyBill;
