import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import checkmark from "../../assets/images/circledCheckmark.svg";
import { FormatAsHundredsOfDollars, FormatCarName } from '../../../utils/Helpers/Format';
import SelectCurrentRate from '../InputComponents/SelectCurrentRate/SelectCurrentRate';
import SelectChargingPattern from '../InputComponents/SelectChargingPattern/SelectChargingPattern';
import SelectRateComparisonVehicle from '../InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle';
import SlideCurrentMonthlyBill from '../InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill';
import SlideMilesDrivenAnnually from '../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import chargingPatterns from '../../data/chargingPatterns';
import ButtonGroupRates from '../InputComponents/ButtonGroupRates/ButtonGroupRates';
import ButtonGroupChargingPatterns from '../InputComponents/ButtonGroupChargingPatterns/ButtonGroupChargingPatterns';
import ToggleIsElectricVehicleOwner from '../InputComponents/ToggleIsElectricVehicleOwner/ToggleIsElectricVehicleOwner';

import "./RateOptionsWizard.scss";

const RateOptionsWizard = ({
  electricVehicles,
  rates,
  rateTotals,
  selectedVehicle,
  utilityName,
  mostCommonRateName,
  typicalDriverName = "person"
}) => {
  // We don't want react-snap to pre-render this page with the modal open because
  // if it does, the pre-rendered version will be non-dismissable. This is a bit
  // hacky, but should set the initial value for isOpen to false in the CI environment
  // in which react-snap runs
  const [isOpen, setIsOpen] = useState(
    !window.location.host.includes("localhost")
  );
  const [step, setStep] = useState(1);

  const lowestRateTotal = rateTotals.find(t => t.isLowest) || {};
  const totalBillSavings = rateTotals[0].annual - (lowestRateTotal.annual || 0);
  const totalBillSavingsQualifier =
    totalBillSavings < 0 ? "would spend an extra " : "can save ";

  return (
    <Modal
      isOpen={isOpen}
      toggle={e => setIsOpen(false)}
      size="lg"
      className="RateOptionsWizard"
    >
      <ModalHeader toggle={e => setIsOpen(false)} className="border-0">
        <small className="text-muted">Step {step} of 6</small>
      </ModalHeader>
      <ModalBody className="text-center">
        <div className="row">
          {step === 1 && (
            <div className="col-sm-8 offset-sm-2">
              <p className="h1 mb-5">
                What is your average home electric bill?
              </p>
              <SlideCurrentMonthlyBill />
            </div>
          )}
          {step === 2 && (
            <div className="col-sm-8 offset-sm-2">
              <p className="h1">
                Select an electric vehicle that you are interested in buying.
              </p>
              <p className="text-muted mb-4">
                Tip! You can change this later. To find an EV model that suits your lifestyle, use our interactive
                {' '}
                <a
                  href="https://ev.duquesnelight.com/vehicles/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EV Guide
                </a>
                .
              </p>
              <div>
                <SelectRateComparisonVehicle
                  electricVehicles={electricVehicles}
                  selectedVehicle={selectedVehicle}
                />
              </div>
              <div>
                <ToggleIsElectricVehicleOwner />
              </div>

            </div>
          )}
          {step === 3 && (
            <div className="col-sm-8 offset-sm-2">
              <p className="h1">
                On average, how many miles per year do you drive?
              </p>
              <p className="text-muted mb-4">
                Tip! A typical {typicalDriverName} drives ~12,000 miles per year.
              </p>
              <div>
                <SlideMilesDrivenAnnually />
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="col-sm-12">
              <p className="h1">Which charging time-frame suits you best?</p>
              <p className="text-muted mb-4">
                Tip! Charging overnight is most cost-effective.
              </p>
              <div className="d-none d-md-block">
                <ButtonGroupChargingPatterns chargingPatterns={chargingPatterns} />
              </div>
              <div className="d-block d-md-none">
                <SelectChargingPattern chargingPatterns={chargingPatterns} />
              </div>
            </div>
          )}
          {step === 5 && (
            <div className="col-sm-12">
              <p className="h1">What&#39;s your current electric rate?</p>
              {mostCommonRateName && (
                <p className="text-muted mb-4">
                  Tip! Most {utilityName ? `${utilityName} ` : ""}customers are on the {mostCommonRateName} rate.
                </p>
              )}
              <div className="d-none d-md-block">
                <ButtonGroupRates rates={rates} />
              </div>
              <div className="d-block d-md-none">
                <SelectCurrentRate rates={Object.keys(rates).map(key => rates[key])} />
              </div>
            </div>
          )}
          {step === 6 && (
            <div className="col-sm-8 offset-sm-2">
              <img
                className="mb-5"
                src={checkmark}
                alt="green checkmark signifying completeness"
              />
              <div>
                <p className="lead">
                  You {totalBillSavingsQualifier}
                  <span>
                    {FormatAsHundredsOfDollars(Math.abs(totalBillSavings))}
                  </span>
                  {" "}
                  per year by switching to the {utilityName ? `${utilityName} ` : ""}
                  <span className="font-weight-bold">{lowestRateTotal.title}</span>
                  {" "}
                  with a
                  {" "}
                  <span className="font-weight-bold">
                    {`${selectedVehicle.model_year} ${FormatCarName(selectedVehicle)}`}
                  </span>.
                </p>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter
        className="justify-content-center mb-3 border-0"
      >
        {step > 1 && (
          <Button
            className="btn btn-secondary"
            onClick={() => setStep(step - 1)}
          >
            PREVIOUS
          </Button>
        )}
        <button
          type="button"
          className="btn btn-ae"
          onClick={() => {
            if (step === 6) {
              setIsOpen(false);
              return;
            }
            setStep(step + 1);
          }}
        >
          {step === 6 ? "SEE RESULTS" : "NEXT"}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RateOptionsWizard;

RateOptionsWizard.propTypes = {
  electricVehicles: PropTypes.object,
  totalBillSavings: PropTypes.number,
  totalBillSavingsQualifier: PropTypes.string,
  lowestRate: PropTypes.string,
  vehicleName: PropTypes.string
};
