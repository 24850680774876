import React, { Component} from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./../../css/DuquesneFrontend.css"
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout"

import banner from "../../assets/images/banner.jpg";


import homepageScreenshot from "../../assets/images/metaImages/generic-preview.png";
import compareVehicleScreenshot from "../../assets/images/metaImages/compare-vehicle-screenshot.png";
import evCatalogScreenshot from "../../assets/images/metaImages/ev-catalog-screenshot.png";
import evPageScreenshot from "../../assets/images/metaImages/ev-page-screenshot.png";
import homeChargerScreenshot from "../../assets/images/metaImages/home-charger-screenshot.png";
import incentivesScreenshot from "../../assets/images/metaImages/incentives-screenshot.png";
import mapScreenshot from "../../assets/images/metaImages/map-screenshot.png";
import faqScreenshot from "../../assets/images/metaImages/faq-screenshot.png";
import costVsRangeScreenshot from "../../assets/images/metaImages/cost-vs-range-screenshot.png"



// Pages
import Home from "../../components/HomePage/Homepage";
import EVs from "../../../pages/EVs/EVs";
import EVsGraph from "../../../pages/EVs/EVsGraph"
import EV from "../../../pages/EV/EV";
import UsedEV from "../../../pages/UsedEV/UsedEV";
import UsedEVs from "../../../pages/UsedEVs/UsedEVs";
import Incentives from "../../../pages/Incentives/Incentives";
import MapPage from "../../../pages/MapPage/MapPage";
import FAQ from "../../../pages/FAQ/FAQ";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";
import CompareVehicles from "../../../components/CompareVehicles/CompareVehicles"
import HomeChargers from "../../../pages/HomeChargers/HomeChargers"
import RateComparison from '../../pages/RateComparison/RateComparison';

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles"
import fetchIncentives from "../../../services/fetchIncentives";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";
import fetchHomeChargers from "../../../services/fetchHomeChargers"

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import { loadState, persistState } from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";
import { MAP_TABS } from '../../../constants/mapTabs';

class Root extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
      savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};

    this.state = {
      uuid: existingState.uuid || Uuid(),
      ipData: existingState.ipData || null,
      electricVehicles: null,
      usedElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      homeChargers: null,

      userPreferences: loadUserPrefs(savedPrefs),
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();

    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    this.getUserZip = this.getUserZip.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
    this.loadDealers = this.loadDealers.bind(this);
  }

  componentDidMount() {
    this.loadElectricVehicleData();
    this.loadGasolineVehicleData();
    this.loadZipcodeData();
    this.loadIncentivesData();
    this.loadDealers();
    this.loadHomeChargers();

    GaTracker.initialize();
    const page = this.props.location.pathname + this.props.location.search;
    GaTracker.trackPage(page, { userId: this.state.uuid });
    // this.getUserZip();
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.householdSize !==
        prevState.userPreferences.householdSize ||
      this.state.userPreferences.householdIncome !==
        prevState.userPreferences.householdIncome ||
      this.state.userPreferences.canTurnInClunker !==
        prevState.userPreferences.canTurnInClunker ||
      this.state.userPreferences.taxFilingStatus !==
        prevState.userPreferences.taxFilingStatus
    ) {
      this.loadElectricVehicleData();
      this.loadIncentivesData();
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
        prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
        prevState.userPreferences.vehicleHandleForIncentives
    ) {
      this.loadIncentivesData();
    }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadZipcodeData();
      this.loadDealers();
    }

    let prevZip = prevState.userLocation
      ? prevState.userLocation.postcode
      : null;
    if (
      this.state.userLocation &&
      this.state.userLocation.postcode !== prevZip
    ) {
      this.loadDealers();
    }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
      const { uuid, userPreferences, ipData } = this.state;
      persistState({ uuid, userPreferences, ipData });
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }

    if (this.props.language !== prevProps.language) {
      this.loadIncentivesData();
      this.loadElectricVehicleData();
    }

    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.chargerWifiFilter !==
        prevState.userPreferences.chargerWifiFilter
    ) {
      this.loadHomeChargers();
    }
  }
  getUserZip() {
    let target = "post-code";
    var match = document.cookie.match(
      new RegExp("(^| )" + target + "=([^;]+)")
    );

    if (match) {
      return this.updateUserPreferences({ zipcode: match[2] });
    }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      availability: getUserPref('availability', this.state.userPreferences),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      this.setState({
        electricVehicles: electricVehicles.newElectricVehicles,
        usedElectricVehicles: electricVehicles.usedElectricVehicles,
      });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  async loadGasolineVehicleData() {
    let params = {
      fuel_type: "gas",
      postcode: getUserPref("zipcode", this.state.userPreferences),
    };

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params);
      if (!gasolineVehicles) return;
      this.setState({ gasolineVehicles });
    } catch (e) {}
  }

  async loadIncentivesData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      vehicle_handle: getUserPref(
        "vehicleHandleForIncentives",
        this.state.userPreferences
      ),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      turn_in_clunker: getUserPref(
        "canTurnInClunker",
        this.state.userPreferences
      )
        ? true
        : null,
      tax_filing_type: getUserPref(
        "taxFilingStatus",
        this.state.userPreferences
      ),
      lang: this.props.language === "EN" ? null : this.props.language,
    };

    try {
      const incentives =
        params["vehicle_handle"] !== ""
          ? await fetchVehicleIncentivesWithHandle(params)
          : await fetchIncentives(params);
      if (!incentives) return;
      this.setState({ incentives });
    } catch (e) {
      // TODO: handle error
    }
  }

  async loadHomeChargers() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
    };

    const wifiPrefs = getUserPref(
      "chargerWifiFilter",
      this.state.userPreferences
    );
    if (wifiPrefs.yes) {
      params.wifi = "true";
    } else if (wifiPrefs.no) {
      params.wifi = "false";
    }

    try {
      const homeChargers = await fetchHomeChargers(params);
      if (!homeChargers) return;
      this.setState({ homeChargers });
    } catch (e) {}
  }

  loadDealers() {
    if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
      return;
    }

    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      distance: isIE() ? 25 : 100,
    };
    let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/dealers`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      })
      .then((response) => {
        if (response.status === 400) {
          this.setState({
            userLocationDealersNotFound: true,
          });
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.dealers) {
          this.setState({
            dealerLocations: data.dealers,
            userLocationDealersNotFound: false,
          });
        }
      });
  }

  loadZipcodeData() {
    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      distance: 0,
    };
    let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/location`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      })
      .then((response) => {
        if (response.status === 400) {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false,
          });
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.location) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false,
          });
          let newPrefs = {
            salesTax:
              data?.location?.regional_financial_references?.[0]?.sales_tax?.region /
              100.0,
          }
          this.updateUserPreferences(newPrefs);
          this.incentivePrefsModalToggle(false);
        }
      });
  }
  geolocateUser() {
    let url = new URL(
      `https://api.ipstack.com/check?format=json&access_key=${process.env.REACT_APP_IPSTACK_KEY}`
    );
    window
      .fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ ipData: data });
      });
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs,
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
      });
    }
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;
    const language = this.props.language;
    const changeLanguage = this.props.changeLanguage;

    const userPrefs = {
      get: (key) => getUserPref(key, this.state.userPreferences),
      getPreset: (key) => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
      syncWorkingZipcode: () => {
        this.updateUserPreferences({
          zipcode: getUserPref("workingZipcode", this.state.userPreferences),
        });
      },
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  description="Learn more about electric vehicles (EVs) incentives"
                  disclaimers="EV Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information."
                  page="home"
                  title={process.env.REACT_APP_PAGES_HOME_TITLE}
                  image={homepageScreenshot}
                >
                  <Home
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    userPreferences={this.state.userPreferences}
                    userLocation={this.state.userLocation}
                    homePageBannerImage={banner}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              );
            }}
          />
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
            <Route
              exact
              path="/ev101"
              render={(props) => {
                return (
                  <PageLayout
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                    page="faq"
                    title={process.env.REACT_APP_PAGES_FAQ_TITLE}
                    image={faqScreenshot}
                  >
                    <FAQ ip={ip} uuid={uuid} />;
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
            <Route
              exact
              path="/vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    description=""
                    page="vehicles"
                    activePage="vehicles"
                    title={process.env.REACT_APP_PAGES_VEHICLES_TITLE}
                    image={evCatalogScreenshot}
                  >
                    <EVs
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
              exact
              path="/used-vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    ip={ip}
                    e
                    uuid={uuid}
                    activePage="vehicles"
                    title={process.env.REACT_APP_PAGES_USED_VEHICLES_TITLE}
                    image={evCatalogScreenshot}
                  >
                    <UsedEVs
                      {...props}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
              path="/used-vehicles/:evId"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    ip={ip}
                    uuid={uuid}
                    disclaimers=""
                    activePage="vehicles"
                    title={process.env.REACT_APP_PAGES_VEHICLES_MODEL_TITLE}
                    image={evPageScreenshot}
                  >
                    <UsedEV
                      {...props}
                      electricVehicles={this.state.usedElectricVehicles}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
            <Route
              exact
              path="/incentives"
              render={(props) => (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="incentives"
                  description=""
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                  title={process.env.REACT_APP_PAGES_INCENTIVES_TITLE}
                  image={incentivesScreenshot}
                >
                  <Incentives
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              )}
            />
          ) : null}
          {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.url}
                render={(props) => {
                  return (
                    <PageLayout
                      language={language}
                      changeLanguage={changeLanguage}
                      props={props}
                      ip={ip}
                      uuid={uuid}
                      page="map"
                      description={tab.description}
                      title={process.env.REACT_APP_PAGES_CHARGING_TITLE}
                      image={mapScreenshot}
                    >
                      <MapPage
                        {...props}
                        tabId={tab.id}
                        userLocation={this.state.userLocation}
                        ip={ip}
                        uuid={uuid}
                        dealerLocations={this.state.dealerLocations}
                        title={tab.title}
                        zipcode={userPrefs.get("zipcode")}
                      />
                    </PageLayout>
                  );
                }}
              />
            );
          })}
          {process.env.REACT_APP_PAGES_VEHICLES_MODEL_ENABLED ? (
            <Route
              path="/vehicles/:evId"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    ip={ip}
                    uuid={uuid}
                    description="Compare Electric Vehicles (EVs) by price, range and features."
                    disclaimers=""
                    activePage="vehicles"
                    title={process.env.REACT_APP_PAGES_VEHICLES_MODEL_TITLE}
                    image={evPageScreenshot}
                  >
                    <EV
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
            <Route
              exact
              path="/compare-vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="compare"
                    ip={ip}
                    uuid={uuid}
                    title={process.env.REACT_APP_PAGES_COMPARE_TITLE}
                    image={compareVehicleScreenshot}
                  >
                    <CompareVehicles
                      props={props}
                      ip={this.state.ipData ? this.state.ipData.ip : null}
                      uuid={this.state.uuid}
                      electricVehicles={this.state.electricVehicles}
                      gasolineVehicles={this.state.gasolineVehicles}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
            <Route
              exact
              path="/comparison-graph"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="cost vs range"
                    ip={ip}
                    uuid={uuid}
                    title={process.env.REACT_APP_PAGES_COMPARISON_GRAPH_TITLE}
                    image={costVsRangeScreenshot}
                  >
                    <EVsGraph
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                      displayGraph={true}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
            <Route
              exact
              path="/home-chargers"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="home chargers"
                    title={process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE}
                    image={homeChargerScreenshot}
                  >
                    <HomeChargers
                      {...props}
                      homeChargers={this.state.homeChargers}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_RATES_ENABLED ? (
            <Route
              exact
              path="/rates"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="rates"
                    pageid="rate-advisor"
                    title={process.env.REACT_APP_PAGES_RATES_TITLE}
                    image={homepageScreenshot}
                  >
                    <RateComparison
                      {...props}
                      ip={ip}
                      uuid={uuid}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}

    export default withRouter(Root);
